@import '../../../assets/sass/_variables.scss';

.tariffs-cards,
.modality-cards {
    border: 1px solid #ccc;
    border-radius: 8px;

    .tariff-card-name,
    .modality-card-name {
        border-radius: 8px 8px 0 0;
        padding: 5px 10px;

        * .dropstart {

            & button {
                background-color: transparent;
                border: none;
                padding: 0 12px;
                color: #fff;
            }

            & *::before {
                display: none;
            }
        }

        &.primary {
            background-color: $primaryColor;

            p,
            span,
            b {
                color: #fff;
            }
        }

        &.disabled {
            background-color: #ccc;

            p,
            span,
            b {
                color: #000;
            }
        }

        &.normal {
            background-color: #fff;

            p,
            span,
            b {
                color: #000;
            }

            * .dropstart {

                & button {
                    background-color: transparent;
                    border: none;
                    padding: 0 12px;
                    color: #000;
                }

                & *::before {
                    display: none;
                }
            }
        }


    }

    .tariff-card-info,
    .modality-card-info {
        padding: 0px 10px;

        .weekDays {
            padding: 0 10px;
            display: flex;
            justify-content: space-between;

            >span {
                padding: 0;
            }

            >div {
                max-width: 12%;
                padding: 0px;

                width: 100%;
                /* margin: 0 0 0 8px; */

                .btn {
                    width: 100%;
                    padding: 5px 0;

                    &.btn-disabled {
                        color: #000;
                        background-color: #ccc;
                        border-color: #ccc;
                    }

                    span {
                        white-space: nowrap;
                    }
                }
            }
        }

        .btn-disabled {
            color: #000;
            background-color: #ccc;
            border-color: #ccc;
        }
    }

    .accordion-button:not(.collapsed) {
        color: #212529;
    }

    .accordion-button {
        padding: 0 15px 0 0;
        height: unset;
    }

    .accordion-body {
        padding: 0;
    }
}

.novo-tarifario-cadastrado {
    .new-card {
        background-color: #cccccc3d;
        padding: 5px 10px;
        border-radius: 5px;

        .info {
            &.bordered {
                border-bottom: 1px solid #6c6c6c47;
            }

            span {
                font-size: 12px;
            }
        }

        * .dropstart {

            & button {
                background-color: transparent;
                border: none;
                padding: 0 12px;
                color: #6c6c6c;

            }

            & *::before {
                display: none;
            }
        }
    }
}

.antecedencia-compra {
    .row {
        border: 1px solid #ddd;
        border-radius: 8px;
        margin: 0;
    }

    button {
        background-color: #f2f2f2 !important;
        border: none;
        border-radius: 50%;
        color: #000 !important;
    }

    input {
        max-width: 40px;
        padding: 0;
        margin: 0;
        border: none;
        box-shadow: none;
    }

    .antecedencias{
        max-width: unset;
        background-color: #fff !important;
    }

    .inputAntecedencia {
        cursor: pointer;
        border: 1px solid #ced4da;
        box-shadow: 0px 4px 2.5px 0px #70707025;
        width: 100%;
    }

    .panels {
        display: none;
        position: absolute;
        z-index: 100;
        background-color: #fff;
        top: 30px;
    }
}
@import "../../../../assets/sass/_variables.scss";

.suppliers-financial-table {
    & .table-container {
        & thead tr th:nth-child(1),
        tbody tr td:nth-child(1) {
            width: 55%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        & thead tr th:nth-child(2),
        tbody tr td:nth-child(2) {
            width: 40%;
        }

        & thead tr th:nth-child(3),
        tbody tr td:nth-child(3) {
            width: 5%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}


.session-b-bills-receive-table {
    & .table-container {
        & thead tr th:nth-child(1),
        tbody tr td:nth-child(1) {//forma pag
            width: 7%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        & thead tr th:nth-child(2),
        tbody tr td:nth-child(2) {//adquirente
            width: 7%;
        }

        & thead tr th:nth-child(3),
        tbody tr td:nth-child(3) {//descricao
            width: 22%;
        }

        & thead tr th:nth-child(4),
        tbody tr td:nth-child(4) {//conta contabil
            width: 7%;
        }

        & thead tr th:nth-child(5),
        tbody tr td:nth-child(5) {//cliente
            width: 7%;
        }

        & thead tr th:nth-child(6),
        tbody tr td:nth-child(6) {// qtd
            width: 4%;
        }

        & thead tr th:nth-child(7),
        tbody tr td:nth-child(7) {//adt
            width: 4%;
        }

        & thead tr th:nth-child(8),
        tbody tr td:nth-child(8) {//chd
            width: 4%;
        }

        & thead tr th:nth-child(9),
        tbody tr td:nth-child(9) {//inf
            width: 4%;
        }

        & thead tr th:nth-child(10),
        tbody tr td:nth-child(10) {//valor
            width: 6.5%;
        }

        & thead tr th:nth-child(11),
        tbody tr td:nth-child(11) {//venc
            width: 8%;
        }

        & thead tr th:nth-child(12),
        tbody tr td:nth-child(12) {//obs
            width: 9%;
        }

        & thead tr th:nth-child(13),
        tbody tr td:nth-child(13) {//status
            width: 8%;
        }

        & thead tr th:nth-child(14),
        tbody tr td:nth-child(14) {//receita
            width: 6.5%;
        }

        & thead tr th:nth-child(15),
        tbody tr td:nth-child(15) {//fatura
            width: 4%;
        }

        & thead tr th:nth-child(16),
        tbody tr td:nth-child(16) {//nota fiscal
            width: 4%;
        }

        & thead tr th:nth-child(17),
        tbody tr td:nth-child(17) {//acoes
            width: 4%;
            text-align: center;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

    }
}
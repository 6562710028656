@import "../../../../assets/sass/_variables.scss";

.sum-of-the-month-table {
    & .table-container {
        & thead tr th:nth-child(1),
        tfoot tr th:nth-child(1),
        tbody tr td:nth-child(1) { // total receber
            width: 28%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        & thead tr th:nth-child(2),
        tfoot tr th:nth-child(2),
        tbody tr td:nth-child(2) { // total pagar
            width: 25%;
        }

        & thead tr th:nth-child(3),
        tfoot tr th:nth-child(3),
        tbody tr td:nth-child(3) { // total margem
            width: 25%;
        }

        & thead tr th:nth-child(4),
        tfoot tr th:nth-child(4),
        tbody tr td:nth-child(4) { // taxa margem
            width: 22%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}

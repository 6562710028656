.session-closure-table {
    & .table-container {
        & .table {
            & thead tr th:nth-child(1),
            tbody tr td:nth-child(1) {//data
                width: 8%;
                padding-left: 20px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
    
            & thead tr th:nth-child(2),
            tbody tr td:nth-child(2) {//tipo
                width: 6%;
            }
    
            & thead tr th:nth-child(3),
            tbody tr td:nth-child(3) {//session
                width: 6%;
            }
    
            & thead tr th:nth-child(4),
            tbody tr td:nth-child(4) {//desc
                width: 22%;
            }
    
            & thead tr th:nth-child(5),
            tbody tr td:nth-child(5) {//val r
                width: 8%;
            }
    
            & thead tr th:nth-child(6),
            tbody tr td:nth-child(6) {//val p
                width: 8%;
            }
    
            & thead tr th:nth-child(7),
            tbody tr td:nth-child(7) {//val m
                width: 8%;
            }
    
            & thead tr th:nth-child(8),
            tbody tr td:nth-child(8) {//tax m
                width: 7%;
            }
    
            & thead tr th:nth-child(9),
            tbody tr td:nth-child(9) {//status r
                width: 8%;
            }
    
            & thead tr th:nth-child(10),
            tbody tr td:nth-child(10) {//status p
                width: 8%;
            }
    
            & thead tr th:nth-child(11),
            tbody tr td:nth-child(11) {//status s
                width: 8%;
            }
    
            & thead tr th:nth-child(12),
            tbody tr td:nth-child(12) {//acoes
                width: 3%;
                text-align: center;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
}

.rangecomission {
    margin-left: 10px;
    font-size: 12px;
    width: auto;
    display: inline-block;
    background-color: #282c34;
    border-radius: 4px;
    padding: 5px 15px;
    color: #fff;

    .bronze{
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
        padding: 0px;
        background: #B87333;
        color: #9A5515;
        background: linear-gradient(to bottom, #B87333 0%, #A96424 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #B87333), color-stop(100%, #A96424));
        background: -webkit-linear-gradient(top, #B87333 0%, #A96424 100%);
        background: -moz-linear-gradient(top, #B87333 0%, #A96424 100%);
        background: -o-linear-gradient(top, #B87333 0%, #A96424 100%);
        background: -ms-linear-gradient(top, #B87333 0%, #A96424 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#B87333', endColorstr='#A96424', GradientType=0);
        border: 1px solid #9A5515;
        text-shadow: 0 1px 0 #C78242;
        -webkit-text-shadow: 0 1px 0 #C78242;
        -moz-text-shadow: 0 1px 0 #C78242;
        box-shadow: inset 0 1px 0 #C78242;
        -webkit-box-shadow: inset 0 1px 0 #C78242;
        -moz-box-shadow: inset 0 1px 0 #C78242;

    }

    .prata{
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
        padding: 0px;
        background: #91A3B0;
        color: #738592;
        background: linear-gradient(to bottom, #91A3B0 0%, #8294A1 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #91A3B0), color-stop(100%, #8294A1));
        background: -webkit-linear-gradient(top, #91A3B0 0%, #8294A1 100%);
        background: -moz-linear-gradient(top, #91A3B0 0%, #8294A1 100%);
        background: -o-linear-gradient(top, #91A3B0 0%, #8294A1 100%);
        background: -ms-linear-gradient(top, #91A3B0 0%, #8294A1 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#91A3B0', endColorstr='#8294A1', GradientType=0);
        border: 1px solid #738592;
        text-shadow: 0 1px 0 #A0B2BF;
        -webkit-text-shadow: 0 1px 0 #A0B2BF;
        -moz-text-shadow: 0 1px 0 #A0B2BF;
        box-shadow: inset 0 1px 0 #A0B2BF;
        -webkit-box-shadow: inset 0 1px 0 #A0B2BF;
        -moz-box-shadow: inset 0 1px 0 #A0B2BF;

        
    }

    .ouro{
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
        padding: 0px;
        background: #FFDF00;
        color: #E1C100;
        background: linear-gradient(to bottom, #FFDF00 0%, #F0D000 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFDF00), color-stop(100%, #F0D000));
        background: -webkit-linear-gradient(top, #FFDF00 0%, #F0D000 100%);
        background: -moz-linear-gradient(top, #FFDF00 0%, #F0D000 100%);
        background: -o-linear-gradient(top, #FFDF00 0%, #F0D000 100%);
        background: -ms-linear-gradient(top, #FFDF00 0%, #F0D000 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFDF00', endColorstr='#F0D000', GradientType=0);
        border: 1px solid #E1C100;
        text-shadow: 0 1px 0 #FFEE0F;
        -webkit-text-shadow: 0 1px 0 #FFEE0F;
        -moz-text-shadow: 0 1px 0 #FFEE0F;
        box-shadow: inset 0 1px 0 #FFEE0F;
        -webkit-box-shadow: inset 0 1px 0 #FFEE0F;
        -moz-box-shadow: inset 0 1px 0 #FFEE0F;
    }

    .platina{
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
        padding: 0px;
        background: #44D7A8;
        color: #26B98A;
        background: linear-gradient(to bottom, #44D7A8 0%, #35C899 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #44D7A8), color-stop(100%, #35C899));
        background: -webkit-linear-gradient(top, #44D7A8 0%, #35C899 100%);
        background: -moz-linear-gradient(top, #44D7A8 0%, #35C899 100%);
        background: -o-linear-gradient(top, #44D7A8 0%, #35C899 100%);
        background: -ms-linear-gradient(top, #44D7A8 0%, #35C899 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#44D7A8', endColorstr='#35C899', GradientType=0);
        border: 1px solid #26B98A;
        text-shadow: 0 1px 0 #53E6B7;
        -webkit-text-shadow: 0 1px 0 #53E6B7;
        -moz-text-shadow: 0 1px 0 #53E6B7;
        box-shadow: inset 0 1px 0 #53E6B7;
        -webkit-box-shadow: inset 0 1px 0 #53E6B7;
        -moz-box-shadow: inset 0 1px 0 #53E6B7;

    }

    .diamante{
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
        padding: 0px;
        background: #ADD8E6;
        color: #8FBAC8;
        background: linear-gradient(to bottom, #ADD8E6 0%, #9EC9D7 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ADD8E6), color-stop(100%, #9EC9D7));
        background: -webkit-linear-gradient(top, #ADD8E6 0%, #9EC9D7 100%);
        background: -moz-linear-gradient(top, #ADD8E6 0%, #9EC9D7 100%);
        background: -o-linear-gradient(top, #ADD8E6 0%, #9EC9D7 100%);
        background: -ms-linear-gradient(top, #ADD8E6 0%, #9EC9D7 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ADD8E6', endColorstr='#9EC9D7', GradientType=0);
        border: 1px solid #8FBAC8;
        text-shadow: 0 1px 0 #BCE7F5;
        -webkit-text-shadow: 0 1px 0 #BCE7F5;
        -moz-text-shadow: 0 1px 0 #BCE7F5;
        box-shadow: inset 0 1px 0 #BCE7F5;
        -webkit-box-shadow: inset 0 1px 0 #BCE7F5;
        -moz-box-shadow: inset 0 1px 0 #BCE7F5;

    }
}
@import "../../../../../assets/sass/_variables.scss";

.edit-partners-data .observation{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
}

.edit-partners-data .observation-content{
    width: 100%;
    background-color: white;
    border-left: 10px solid $primaryColor;
    text-align: left;
}

.edit-partners-data .observation-content p{
    font-size: 12px;
    margin-bottom: 9px;
    margin-left: 9px;
    margin-top: 6px;
}

.edit-partners-data .edit{
    width: 100%;
}

.edit-partners-data .steps{
    width: 14.5%;
    margin-top: 40px;
}

.edit-partners-data .step-unit{
    margin-bottom: 15px;
}

.edit-partners-data .step-unit.active span{
    color: white;
    width: 25px;
    height: 25px;
    background-color: $primaryColor;
    border-radius: 50%;
    margin-right: 5px;
    text-align: center;
}

.edit-partners-data .step-unit.active p{
    color: $primaryColor;
    font-weight: bold;
}

.edit-partners-data .step-unit span{
    color: white;
    width: 25px;
    height: 25px;
    background-color: #707070;
    border-radius: 50%;
    margin-right: 5px;
    text-align: center;
}

.edit-partners-data .step-unit p{
    color: #707070;
    font-weight: bold;
}

.edit-partners-data .border{
    box-shadow: rgba(0, 0, 0, 0.20) 0px 3px 5px;
    margin-bottom: 75px;
    width: 100%;
}

.edit-partners-data .edit-title{
    display: flex;
    justify-content: space-between;
    background-color: white;
}

.edit-partners-data .edit-title h3{
    text-align: left;
    font-size: 18px;
    margin: 10px 0 20px 15px;
    color: $primaryColor;
}

.edit-partners-data .edit-title button{
    width: 120px;
    height: 38px;
    background-color: transparent;
    border: 1px solid $primaryColor;
    border-radius: 6px;
    opacity: 1;
    color: $primaryColor;
    transition: background-color .3s;
}
.edit-partners-data .edit-title button:hover{
    color: #fff;
    background-color: $primaryColor;;
}

.edit-partners-data .data{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 1.5rem;
    background-color: white;
    
}
.edit-partners-data .data-unit{
    max-width: 30%;
    width: 100%;
    height: 72px;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 0 1.5rem;
    margin: 10px 0;
    & p:nth-child(1) {
        font-size: 12px;
        font-weight: 600;
    }
}
.data-unit-white-bg{
    background-color: white !important;
}

.edit-partners-data p{
    margin: 0;
}

.edit-partners-data .step-unit a {
    display: flex;
    text-decoration: none;
}

.edit-partners-data .steps-sticky {
    position: sticky;
    top: 20px;
    font-size: 12px;
    line-height: 24px;
}

.scroll-click {
    color: $primaryColor;
    font-weight: bold;
}

.cards .card-unit .card {
    display: flex;
    flex-direction: row;
    padding: 7px;
    border-radius: 0;
    border-left: 2px solid var(--primary);
}
.btn-primary-log {
    background-color: $primaryColor !important;
    color: #fff !important;
    text-transform: uppercase;
    &:hover {
        background-color: #004921 !important;
    }
}
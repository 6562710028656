.merchants-tax-table {
    & .table-container {
        & thead tr th:nth-child(1),
        tbody tr td:nth-child(1) {
            width: 10%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        & thead tr th:nth-child(2),
        tbody tr td:nth-child(2) {
            width: 10%;
        }

        & thead tr th:nth-child(3),
        tbody tr td:nth-child(3) {
            width: 20%;
        }

        & thead tr th:nth-child(4),
        tbody tr td:nth-child(4) {
            width: 10%;
        }

        & thead tr th:nth-child(5),
        tbody tr td:nth-child(5) {
            width: 10%;
        }

        & thead tr th:nth-child(6),
        tbody tr td:nth-child(6) {
            width: 10%;
        }

        & thead tr th:nth-child(7),
        tbody tr td:nth-child(7) {
            width: 20%;
        }

        & thead tr th:nth-child(8),
        tbody tr td:nth-child(8) {
            width: 5%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}

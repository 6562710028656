.session-bills-receive-table {
    & .table-container {
        & thead tr th:nth-child(1),
        tbody tr td:nth-child(1) {//cod
            width: 7%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        & thead tr th:nth-child(2),
        tbody tr td:nth-child(2) {//formapag
            width: 9%;
        }

        & thead tr th:nth-child(3),
        tbody tr td:nth-child(3) {//descr
            width: 28%;
        }

        & thead tr th:nth-child(4),
        tbody tr td:nth-child(4) {//cliente
            width: 10%;
        }

        & thead tr th:nth-child(5),
        tbody tr td:nth-child(5) {//qtd
            width: 5%;
        }

        & thead tr th:nth-child(6),
        tbody tr td:nth-child(6) {
            width: 5%;
        }

        & thead tr th:nth-child(7),
        tbody tr td:nth-child(7) {
            width: 5%;
        }

        & thead tr th:nth-child(8),
        tbody tr td:nth-child(8) {
            width: 5%;
        }

        & thead tr th:nth-child(9),
        tbody tr td:nth-child(9) {//valor
            width: 6.5%;
        }

        & thead tr th:nth-child(10),
        tbody tr td:nth-child(10) {//venc
            width: 8%;
        }

        & thead tr th:nth-child(11),
        tbody tr td:nth-child(11) {//obs
            width: 9%;
        }

        & thead tr th:nth-child(12),
        tbody tr td:nth-child(12) {//status
            width: 3%;
        }

        & thead tr th:nth-child(13),
        tbody tr td:nth-child(13) {//acoes
            width: 4%;
            text-align: center;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}

.bill-receive-items-table {
    & .table-container {
        & thead tr th:nth-child(1),
        tbody tr td:nth-child(1) { // data
            width: 15%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        & thead tr th:nth-child(2),
        tbody tr td:nth-child(2) { // servico
            width: 25%;
        }

        & thead tr th:nth-child(3),
        tbody tr td:nth-child(3) { // valor
            width: 13%;
        }

        & thead tr th:nth-child(4),
        tbody tr td:nth-child(4) { // numero pax
            width: 5%;
        }

        & thead tr th:nth-child(5),
        tbody tr td:nth-child(5) { // centro custo
            width: 15%;
        }

        & thead tr th:nth-child(6),
        tbody tr td:nth-child(6) { // acoes
            width: 5%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}

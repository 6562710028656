@import "../../../../assets/sass/_variables.scss";


.passengers-table {

    & thead tr th:nth-child(1),
    tbody tr td:nth-child(1) {
        width: 10%;
        padding-left: 20px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    & thead tr th:nth-child(2),
    tbody tr td:nth-child(2) {
        width: 16%;
    }

    & thead tr th:nth-child(3),
    tbody tr td:nth-child(3) {
        width: 12%;

    }

    & thead tr th:nth-child(4),
    tbody tr td:nth-child(4) {
        width: 7%;

    }

    & thead tr th:nth-child(5),
    tbody tr td:nth-child(5) {
        width: 10%;
    }

    & thead tr th:nth-child(6),
    tbody tr td:nth-child(6) {
        width: 20%;
    }

    & thead tr th:nth-child(7),
    tbody tr td:nth-child(7) {
        width: 10%;
    }

    & thead tr th:nth-child(8),
    tbody tr td:nth-child(8) {
        width: 10%;
    }

    & thead tr th:nth-child(9),
    tbody tr td:nth-child(9) {
        width: 5%;
        text-align: center;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    & tr {
        min-height: 72px;
    }
}

.subtitle-change-session {
    color: $primaryColor;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.dropdown-custom {
    & .dropdown-menu {
        & button {
            color: #6F6F6F;
            &:hover {
                color: #FFF !important;
            }
        }
    }
}
.pt-content.summary {
    & .btn-edit {
        border: 1px solid #707070;
        border-radius: 4px;
        color: #707070;
        padding: .05rem .75rem;
        font-size: .875rem;
        max-height: 25px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            opacity: .6;
        }
    }
}

.summary-sortable-knob{
    cursor: grab;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.summary-sortable-knob:active{
    cursor: grabbing;
}
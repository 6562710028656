@import "./../../../assets/sass/_variables.scss";

.body-not-found {
    background-color: $primaryColor;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.not-found {
    color: #FFF;
    font-size: 1.25rem;
    background-color: unset;
    & h3 {
        color: white;
        margin: 20px 0 30px;
        font-weight: 400;
    }
    & .link-home {
        color: #FFF;
        background-color: $secondaryColor;
        padding: 10px 20px;
        border-radius: 5px;
        text-decoration: none;
        &:hover {
            opacity: .8;
            color: #FFF;
        }
    }
}


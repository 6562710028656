// .bg-register-image {
//     background: url("../img/login/login-img-02.jpg");
//     background-position: center;
//     background-size: cover;
//   }

//   .bg-password-image {
//     background: url("../img/login/login-img-02.jpg");
//     background-position: center;
//     background-size: cover;
//   }

@import '../../assets/sass/_variables.scss';

.title-login {
  color: #666;
}

.link-login {
  & a {
    text-decoration: none;

    &:hover {
      color: #224abe;
      text-decoration: underline;
    }
  }
}

#random {
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.login-brand img {
  width: 80px;
}

.brand-text {
  font-size: 32px !important;
  font-weight: bold;
}

.valign {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 520px !important;
  height: auto;
  z-index: 9;
  box-shadow: rgba(0, 0, 0, 0.54) 0px 3px 8px;
  & h1 {
    font-size: 32px;
    color: #666666;
    font-weight: bold;
  }
  & p {
    font-size: 12px;
    color: #666666;
    font-weight: 400;
    margin-bottom: 52px;
  }
  & label {
    margin-bottom: 8px;
  }
  & .btn-login {
    margin: 15px 0;
    width: 100%;
    height: 40px;
  }
  & .link-login {
    margin: 20px 0 38px;
  }
}

.split-screen {
  height: 100vh;
}

.split-screen .left .inner,
.split-screen .right .inner {
  width: 520px;
  margin: 0 auto;
  padding: 35px 50px;
  position: relative;
}

.expired-session .modal-body {
  margin: 50px 20px;
}

.bg-login-image::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: #000;
  opacity: .65;
  z-index: 1;
}

.logo-login {
  z-index: 999;
  & img {
    width: 220px;
    z-index: 999;
  }
}

.iconsPassword {
  position: absolute;
  right: 10px;
  top: 40px;
  cursor: pointer;
}

@media (max-width: 1400px) {
  .valign {
    
    p {
      margin-bottom: 30px;
    }

    .btn-login {
      margin: 25px 0;
    }
  }

}

@media (max-width: 480px) {
  .logo-login {
    margin: 150px 0px;
  }
  .valign {
    align-items: center;
    width: 360px !important;
    margin-bottom: 20px !important;
  }
  .split-screen .left .inner, .split-screen .right .inner {
    width: 360px;   
    padding: 35px 35px;
  }
  .content-split.row {
    justify-content: center;
  }
  .split-screen {
    height: 100%;
    background-color: #00000090;
  }
  .bg-login-image::after{
    background: transparent;
  }
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to { 
      transform: rotate(360deg);
  }
}


@-webkit-keyframes rotate {
  from {
      -webkit-transform: rotate(0deg);
  }
  to { 
      -webkit-transform: rotate(360deg);
  }
}

.load {
  width: 20px;
  height: 20px;
  margin: 0 auto;
  border: solid 3px white;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name:             rotate; 
  -webkit-animation-duration:         1.0s; 
  -webkit-animation-iteration-count:  infinite;
  -webkit-animation-timing-function: linear;
  transition: all 0.5s ease-in;
  animation-name:             rotate; 
  animation-duration:         1.0s; 
  animation-iteration-count:  infinite;
  animation-timing-function: linear; 

  &.primary{
    border: solid 3px $primaryColor;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
}


.partners-filter .radioPersonal {
    border: 1px solid;
    padding: 0 0 0 30px;
}

.table > :not(:first-child){
    border-top: none;
}

.ranger-calendar .rmdp-container {
    width: 100%;
}

.ranger-calendar .form-control[readonly]{
    background-color: transparent;
}

.check-switch {
    & .col-md-4 {
        line-height: initial;
    }
    & input {
        height: 1.25em !important;
        padding: 0;
        color: #4D814B !important;
        //border-color: #4D814B !important;
        //border: none !important;
        //background-color: #4D814B !important;
        cursor: pointer;
        &:checked {
            background-color: #4D814B !important;
            border: none !important;
        }
    }
}
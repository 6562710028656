@import "../../../assets/sass/_variables.scss";

.check-cat {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3px 0;
    & img {
        width: 18px;
        margin: 5px 2px;
    }
    & input {
        margin: 5px 15px 5px 0;
    }
}

.content-header .container-fluid.my-0.my-md-4 {
    position: absolute;
    z-index: 99;
    bottom: -50px;
}

.card-filter {
    & input[type="range"] {
        width: 100%;
    }

    & input[type="checkbox"] {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }      
    & input[type="checkbox"] + label {
        position: relative;
        padding-left: 30px;
    }

    & input[type="checkbox"] + label::before {
        content: "";
        position: absolute;
        width: 1.1rem;
        height: 1.1rem;
        left: 0;
        bottom: 4px;
        border: solid 1px #adb5bd;
        border-radius: .25rem;
        vertical-align: bottom;
    }

    & input[type="checkbox"]:checked + label::before {
        background-color: $primaryColor;
        border-color:  $primaryColor;
    }

    & input[type="checkbox"]:checked + label::after {
        content: "";
        background-image: url('../../../assets/icons/check.svg');
        position: absolute;
        left: 4px;
        bottom: 8px;
        width: 0.6rem;
        height: 0.6rem;
        //border-right: solid 3px green;
        //border-bottom: solid 3px green;
        //transform: rotate(45deg);
    }
}

.input-range__track--active,
.input-range__slider{
    background-color:  $primaryColor !important;
    border: none;
    border-radius: 0;
    width: 2.5px;
    margin-left: -0.05rem;
    height: 18px;
    margin-bottom: -3px;
}

.input-range__track--background{
    background-color: #fff !important;
    border: 1px solid #b7b7b7;
}

.input-range__track, 
.input-range__track--active {
    height: 10px;
    //margin-right: 10px;
}

.input-range__label-container {
    background-color: $primaryColor !important;
    color: #FFF;
    padding: 2px 3px;
    bottom: 5px;
    font-family: var(--bs-body-font-family);
}

.input-range__label.input-range__label--min .input-range__label-container,
.input-range__label.input-range__label--max .input-range__label-container {
    display: none;
}

.input-range__track--active {
    border: 1px solid $primaryColor;
    bottom: 1px;
}

.check-cat .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 70%;
    top: -2px;
    left: 5px;
    position: relative;
    background-color: $primaryColor;
}
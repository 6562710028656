
$primaryColor:       #00632D !default;
$secondaryColor:     #ffC60A !default;
$success: #7DD4AB !default;

$text-color: #F2F2F2 !default;
$btn-text-color: #fff !default;

$info: #6C6C6C !default;
$gray-100: #F2F2F2 !default;
$color: #6C6C6C !default;

$modal-shadow: #00632D65 !default;

$warning: #FA8E04 !default;

$cart-bottom: #2A2146 !default;

// Font Size
$display-font-sizes: ( 1: 4rem,  2: 3.5rem,  3: 3rem,  4: 2.5rem,  5: 2rem,  6: 1.5rem) !default;

$font-family: 'Nunito'

 
@import '../../assets/sass/variables';

@keyframes printer {
    0% {
        transform: translateY(200px);
        opacity: 1;
    }

    70% {
        transform: translateY(0px);
        opacity: 1;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.printer-icon {
    animation-duration: 2.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: printer;
    animation-timing-function: linear;
    position: relative;
}

.btn-error-outline-primary {
    background-color: #FFF;
    color: var(--primary) !important;
    font-size: .875rem;

    &:hover {
        color: #FFF !important;
    }
}
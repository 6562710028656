.picture-of-the-day-table {
    & .table {
        margin-top: 20px !important;
    }
    & .table-container {
        & thead tr th:nth-child(1),
        tfoot tr th:nth-child(1),
        tbody tr td:nth-child(1) { // tour
            // width: 62%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        & thead tr th:nth-child(2),
        tfoot tr th:nth-child(2),
        tbody tr td:nth-child(2) { // total receber
            width: 12%;
        }

        & thead tr th:nth-child(3),
        tfoot tr th:nth-child(3),
        tbody tr td:nth-child(3) { // total pagar
            width: 12%;
        }

        & thead tr th:nth-child(4),
        tfoot tr th:nth-child(4),
        tbody tr td:nth-child(4) { // total margem
            width: 10%;
        }

        & thead tr th:nth-child(5),
        tfoot tr th:nth-child(5),
        tbody tr td:nth-child(5) { // taxa margem
            width: 10%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        & tr {
            border-radius: 0 !important;
            vertical-align: middle;
            & th, td {
                border-radius: 0 !important;
                vertical-align: middle;
            }
        }
    }
}

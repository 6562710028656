input.input-canal {
    padding-left: 150px;
    max-width: 250px;
}

label.nome-canal.form-label {
    position: absolute;
    margin: 0px;
    padding: 6.5px;
    min-width: 125px;
    text-align: center;
    /* margin-left: 0.5rem; */
    border-right: 1px solid #c2c2c2;
}

.title_stock{
    height: 72px;
    padding: 1rem 0rem;
    margin: 0;
}

// FONT-SIZES

.font-sub-title{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
}

.font-accordion-header-stock span{
    font-size: 18px;
    color: #707070
}

.font-accordion-header-stock button{
    height: 45px;
}
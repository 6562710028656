@import "../../../assets/sass/variables";

.refund-rules-table {
    & .react-bootstrap-table tbody tr {
        border-color: #cecece;
        border-right: 1px solid #cecece;
        border-top: 1px solid #cecece;
        border-bottom: 1px solid #cecece;
        border-left: 1px solid #cecece;
        transition: .3s;

        &:hover {
            border-color: #cecece;
        }
    }

    & .table-container {

        & thead tr th:nth-child(1),
        tbody tr td:nth-child(1) {
            width: 35%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        & thead tr th:nth-child(2),
        tbody tr td:nth-child(2) {
            width: 30%;
        }

        & thead tr th:nth-child(3),
        tbody tr td:nth-child(3) {
            width: 30%;
        }


        & thead tr th:nth-child(4),
        tbody tr td:nth-child(4) {
            width: 5%;
            text-align: center;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

}

.integration-details {

    .header-integration {
        & button {
            color: $primaryColor;
        }
    }
    .form-control.calendar-input {
        padding: 0.375rem 1.75rem;
        max-height: 34px;
        padding-right: 10px !important;
    }

    .modal-header {
        position: unset;
        border-bottom: unset;
        right: unset;
        padding: 0px;
        z-index: unset;
        width: 100%;
        align-items: flex-start;

        .title-reservation {
            font-weight: 700;
        }
    }

    .col-md-12 {
        padding-right: 10px;
    }

    .buttons-options {
        display: flex;

        & .text-danger {

            &:hover {
                background-color: #e9eaee;
            }
        }
    }

    .close {
        background-color: transparent !important;
        border: unset !important;
        font-size: 25px !important;
        width: unset !important;
    }

    .modal-dialog.modal-lg {
        max-width: 1024px;
    }

    .modal-content {
        width: 100% !important;

        .modal-body {
            overflow: auto;
            justify-content: flex-start !important;
        }
    }


    .details-reservations-submit-button {
        background-color: $primaryColor !important;
        color: #fff !important;
        min-height: 38px;
        min-width: 125px;
    }

    span.subtitle-modal {
        font-size: 14px;
        text-transform: uppercase;
        display: flex;
    }

    span.response-modal {
        font-size: 14px;
    }

    .icon-code {
        padding: 12px 4px;
        border-radius: 50%;
        border: 1px solid;
        width: 50px;
        height: 50px;
    }
}
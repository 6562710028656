@import "../../../../assets/sass/_variables.scss";

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

.cancel-load {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    border: solid 8px #707070;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1.0s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &.primary {
        border: solid 3px $primaryColor;
        border-radius: 50%;
        border-right-color: transparent;
        border-bottom-color: transparent;
    }
}

.modal-confirm {

    & #description {
        position: relative;
        top: 0px;
    }

    & .cancel-sitef {
        & .btn {
            border: 1px solid $primaryColor !important;
            font-size: 1rem !important;
            color: $primaryColor;

            &:hover {
                background-color: $primaryColor;
                color: #FFF;
            }
        }
    }
}


@import "../../assets/sass/_variables.scss";

.pt-main-container {
    max-width: 600px;
}

.pt-content {
    border: none;
    padding: 1.5rem 2.5rem;
    //margin-bottom: 2.5rem;

    & .pt-title {
        font-size: 28px;
        font-weight: 700;
        padding-bottom: 30px;
        border-bottom: 1px solid #B5B5B5;
    }

    & .pt-content-info {
        padding: 1.5rem 0 2.5rem;
        border-bottom: 1px solid #B5B5B5;

        & .form-check-label {
            font-size: .875rem;
            font-weight: 400 !important;
            position: relative;
            bottom: 0.1rem;
        }

        & button {
            min-width: 2.25rem;
        }


        & .btn-edit {
            border: 1px solid #707070;
            border-radius: 4px;
            color: #707070;
            padding: .05rem .75rem;
            font-size: .875rem;
            position: relative;
            bottom: 15px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:hover {
                opacity: .6;
            }
        }


        & .input-select-custom {
            & .form-select {
                border: 1px solid #ced4da !important;
            }
        }

        & .small {
            font-weight: 600;
        }

        & .bg-image-info {
            background-color: #deeaff;
            padding: .5rem .75rem;
            border-radius: .5rem;
        }

        & .bg-images {
            background-size: cover;
            background-position: center center;
            cursor: pointer;
            width: 23.65%;
            height: 260px;

            &:first-child {
                width: 100%;
                background-size: 40%;
                background-repeat: no-repeat;
                background-position: left center;
                height: 330px;
            }
        }

        & .btn-image {
            border: 1px solid #707070;

            &:hover {
                opacity: .6;
            }
        }

        & .pt-age-check {

            &.form-switch .form-check-input {
                width: 3rem !important;
                padding: .65rem !important;
            }
        }

        & .pt-google-maps {
            position: relative;
            bottom: 80px;
            height: 390px;
        }

        & .offset-md-6 {
            margin-right: 50%;
            margin-left: 0px;
        }
    }

    & .pt-content-supplier {

        & .pt-subtitle {
            font-size: 1.125rem;
            font-weight: 600;
        }

        & .form-check-label {
            font-size: .875rem;
        }
    }

    & .btn-add-details {
        border: 1px solid #707070;
        border-radius: 4px;
        padding: .05rem .75rem;
        font-size: .875rem;
        font-weight: 600;
        cursor: pointer;

        &:hover {
            opacity: .6;
        }
    }

}

.add-product {
    .card {
        border-radius: 0px;

        &.menu {
            padding: 0px !important;
        }
    }

    .label-small {
        font-size: 14px;
        font-weight: 500;
    }

    .radioPersonal {
        box-shadow: 0px 2px 6px 0px #70707025;
    }
}

.list-menu {
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
}

.sub-list-menu {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.list-menu-item.item-title {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 15px;
    padding: 0px 20px
}

.list-menu-item.item-action {
    font-size: 14px;
    font-weight: 500;
    color: #8E8E8E;
    padding: 0px 30px;

    &.active {
        background-color: #ededed;
    }

    p {
        margin-bottom: 0px;
        border-bottom: 1px solid #EDEDED;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.line-chooice.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.bg-edit-transfer {
    cursor: pointer;

    & p {
        &:hover {
            color: #000000;
        }
    }
}
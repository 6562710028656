
@import "../../assets/sass/_variables.scss";

.channels-table {
    & .table-container {
        background-color: white;
        padding: 20px 25px;
        & .table {

            & thead tr th:nth-child(1),
            tbody tr td:nth-child(1) {
                width: 15%;
                padding-left: 20px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            & thead tr th:nth-child(2),
            tbody tr td:nth-child(2) {
                text-align: left;
                width: 30%;
            }
            
            & thead tr th:nth-child(3),
            tbody tr td:nth-child(3) {
                text-align: left;
                width: 30%;
            }

            & thead tr th:nth-child(4),
            tbody tr td:nth-child(4) {
                text-align: left;
                width: 10%;
            }

            & thead tr th:nth-child(5),
            tbody tr td:nth-child(5) {
                width: 5%;
                text-align: center;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }

    .bg-reservation-ativo {
        background-color: #7DD4AB;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 10px;
        text-align: center;
        width: 100%;
        max-width: unset;
        font-size: 14px;
        line-height: unset;
    }

    .bg-reservation-inativo {
        background-color: #E27376;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 10px;
        text-align: center;
        width: 100%;
        max-width: unset;
        font-size: 14px;
        line-height: unset;
    }
}
@import '../../../../assets/sass/_variables.scss';

.form-inserir{

    & .input-rateio input {
        height: 40px;
    }

    .action-btn{

        span{
            font-weight: 500;
        }

        button{
            color: $primaryColor;
            background-color: transparent;
            border: none;
        }

        ::before{
            display: none;
        }
    }
}


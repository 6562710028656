@import "../../../../../../../assets/sass/_variables.scss";

.table-invoice.filter .table-container {
    width: 100%;
    box-shadow: none !important;
    border: none;
    background-color: white;
    & .table {
        padding: 0;
    }
}

.table-invoice .table-container {
    width: 100%;
    box-shadow: none !important;
    background-color: white;
    border: 1px solid #7070704A;

    & table {
        margin: 0 auto 0 auto
    }
}


.table-invoice .table-title h3 {
    text-align: left;
    font-size: 20px;
    color: $primaryColor;
}

.table-invoice table {
    max-width: 100%;
}

.table-invoice tr th {
    background-color: $primaryColor;
    color: white;
    font-weight: 600;
}

.table-title {
    padding: 1rem 1.25rem;
}

.table-invoice .dropstart .dropdown-toggle::before {
    display: none;
}

.table-invoice button {
    background-color: transparent;
    border: none;
}

.table-invoice #pageDropDown {
    background-color: $primaryColor;
    color: white;
    margin-left: 2%;
    max-width: 70px;
}

.table-invoice .pagination.react-bootstrap-table-page-btns-ul {
    margin-right: 2%;
}

.table-invoice .page-item .page-link {
    color: $primaryColor;
}

.table-invoice .page-item.active .page-link {
    background-color: $primaryColor;
    border-color: $primaryColor;
    color: white;
}

.table-invoice.not-found th,
.table-invoice.not-found td {
    text-align: center;
}

.table-invoice.filter {
    & tr {
        height: 60px;
        font-size: 15px;
    }

    & tbody {
        & tr {
            background-color: #EEEEEE;
        }
    }

    & td {
        display: flex;
        align-items: center;
    }

    & td:nth-child(2) {
        display: grid;
        overflow: hidden;
    }

    & td:nth-child(9) {
        justify-content: center;
    }

    & .bg-analise {
        background-color: $primaryColor;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 10px;
        text-align: center;
    }

    & .bg-ativo {
        background-color: #7DD4AB;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 10px;
        text-align: center;
    }

    & .bg-inativo {
        background-color: #E27376;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 10px;
        text-align: center;
    }

    & .react-bootstrap-table {
        & table {
            border-collapse: inherit;
            border-spacing: 0;
            padding: 0 10px;
        }

        .table> :not(caption)>*>* {
            max-height: 60px;
        }

        & thead {
            & tr {
                margin: 0;
                border: none;
                display: flex;
                align-items: center;

                & th {
                    background-color: #FFF;
                    color: $primaryColor;
                }
            }
        }

        & thead tr th:nth-child(1),
        tbody tr td:nth-child(1) {
            width: 5%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        & thead tr th:nth-child(2),
        tbody tr td:nth-child(2) {
            width: 15%;
        }

        & thead tr th:nth-child(3),
        tbody tr td:nth-child(3) {
            width: 40%;
        }

        & thead tr th:nth-child(4),
        tbody tr td:nth-child(4) {
            width: 15%;
        }

        & thead tr th:nth-child(5),
        tbody tr td:nth-child(5) {
            width: 10%;
        }

        & thead tr th:nth-child(6),
        tbody tr td:nth-child(6) {
            width: 15%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;

        }

        & tr {
            margin: 20px 0;
            border-left: 2px solid $primaryColor;
            //display: table;
            width: 100%;
            border-width: 0;
            border: 1px solid rgb(206, 206, 206);
            border-radius: 5px;
            //vertical-align: middle;
            display: flex;
            //align-items: center;
        }

        .border-analise {
            border-color: $primaryColor;
            border-right: 1px solid rgb(206, 206, 206);
            border-top: 1px solid rgb(206, 206, 206);
            border-bottom: 1px solid rgb(206, 206, 206);
            border-left: 2px solid $primaryColor;
            transition: .3s;

            &:hover {
                border-right: 1px solid $primaryColor;
                border-top: 1px solid $primaryColor;
                border-bottom: 1px solid $primaryColor;
                border-color: $primaryColor;
            }
        }

        .border-ativo {
            border-color: #7DD4AB;
            border-right: 1px solid rgb(206, 206, 206);
            border-top: 1px solid rgb(206, 206, 206);
            border-bottom: 1px solid rgb(206, 206, 206);
            border-left: 2px solid #7DD4AB;
            transition: .3s;

            &:hover {
                border-right: 1px solid #7DD4AB;
                border-top: 1px solid #7DD4AB;
                border-bottom: 1px solid #7DD4AB;
                border-color: #7DD4AB;
            }
        }

        .border-inativo {
            border-color: #E27376;
            border-right: 1px solid rgb(206, 206, 206);
            border-top: 1px solid rgb(206, 206, 206);
            border-bottom: 1px solid rgb(206, 206, 206);
            border-left: 2px solid #E27376;
            transition: .3s;

            &:hover {
                border-right: 1px solid #E27376;
                border-top: 1px solid #E27376;
                border-bottom: 1px solid #E27376;
                border-color: #E27376;
            }
        }


        .table-striped>tbody>tr:nth-of-type(odd)>* {
            --bs-table-accent-bg: #eeeeee;
            color: var(--bs-table-striped-color);
        }
    }
}

.react-bootstrap-table-pagination {
    padding-bottom: 20px;
}

.table-invoice .dropdown-item {
    &:hover {
        & a {
            color: #FFF;
        }
    }
}

.btn-bills {
    border: 1px solid #cecece !important;
    color: #6e707e;
}

.negative {
    color: red;
}

@media (max-width: 1360px) {
    .table-invoice.filter {
        & tr {
            height: 49px;
            font-size: 11.5px;
        }
    }

    .btn-bills {
        font-size: 14px;
    }
}
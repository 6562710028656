@import '../../assets/sass/_variables.scss';

.sales-report {

    .cards {
        margin: 40px 0;

        .card-unit {
            padding-left: 0;

            .card {
                display: flex;
                flex-direction: row;
                padding: 7px;
                border-radius: 0;
                border-left: 2px solid $primaryColor;

                .card-title {
                    color: #A1ADC1;
                    font-weight: 600;
                    font-size: 10px;
                }

                .cart-content {
                    color: $primaryColor;
                    font-weight: 600;
                    font-size: 18px;
                }
            }
        }
    }

    .sales-report-table {
        & .table-container {
            & .table {

                /* & thead tr th,
                tbody tr td {
                    max-width: 1px;
                } */
                
                & thead tr th:nth-child(1),
                tbody tr td:nth-child(1) {
                    width: 10%;
                    padding-left: 20px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                & thead tr th:nth-child(2),
                tbody tr td:nth-child(2) {
                    width: 8%;
                }

                & thead tr th:nth-child(3),
                tbody tr td:nth-child(3) {
                    width: 8%;
                }

                & thead tr th:nth-child(4),
                tbody tr td:nth-child(4) {
                    width: 20%;
                }

                & thead tr th:nth-child(5),
                tbody tr td:nth-child(5) {
                    width: 14%;
                }

                & thead tr th:nth-child(6),
                tbody tr td:nth-child(6) {
                    width: 12%;
                }

                & thead tr th:nth-child(7),
                tbody tr td:nth-child(7) {
                    width: 8%;
                }

                & thead tr th:nth-child(8),
                tbody tr td:nth-child(8) {
                    width: 10%;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
        }

        .text-short{
            text-overflow: ellipsis;
            overflow: hidden;

            .text-nowrap-custom{
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        } 
    }
}

.nav-tabs {
    display: none;
}
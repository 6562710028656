@import "./../../assets/sass/_variables.scss";

#wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.sidebar {
    width: 6.5rem;
    min-height: 100vh;
}


@media (max-width: 1440px){
    .sidebar .nav-item .nav-link {
        padding: 0.50rem 1rem !important;
    }
    
    #accordionSidebar .svg-inline--fa {
        height: 20px !important;
    }
}



@media (min-width: 768px){
    .sidebar.toggled {
        overflow: visible;
        width: 6.5rem !important;
        max-width: 100px;
    }
   
    .sidebar.toggled .nav-item .nav-link {
        text-align: center;
        padding: 0.75rem  1rem;
        width: 6.5rem;
        max-width: 100px;
    }
}



.sidebar .nav-item .nav-link {
    display: block;
    width: 100%;
    text-align: left;
    width: 14rem;
}  

.sidebar .nav-item .nav-link {
    text-align: center;
    padding: 0.75rem 1rem;
    width: 6.5rem;
} 

.sidebar .sidebar-brand {
    height: 4.375rem;
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 800;
    padding: 3.5rem 1rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    z-index: 1;
}

.sidebar.toggled .nav-item .nav-link span {
    font-size: 0.75rem;
    display: block;
}

.nav-link {
    color: $primaryColor !important;
}

.nav-link:hover, .nav-link:focus{
    background-color: #c3c8d170;
}

.sidebar-brand{
    border-bottom: 4px solid $primaryColor;
}

#accordionSidebar .svg-inline--fa {
    width: auto;
    height: 22px;
}

.fa-times{
    cursor: pointer;
}

li.submenu-title {
    margin: 0px;
    padding-left: 30px;
    font-weight: 700;
    color: #b7bbc5;
    margin-top: 25px;
}

@media (max-width: 1400px){
    .nav-link.select span.menu-name {
        display: none !important;
    }

    .sidebar.toggled {
        width: 4rem !important;
    }

    .sidebar.toggled .nav-item .nav-link {
        text-align: center;
        padding: 0.75rem 1rem;
        width: 4rem;
    }

    .sidebar .nav-item .nav-link {
        padding: 0.8rem !important;
    }
}

@import "../../../../assets/sass/_variables.scss";


.weekDay{
    color: #fff;
    background-color: transparent;
    border-radius: 0.25rem;    
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;    
    margin: 0.15rem 0.375rem;
    padding: 0.375rem 0.75rem;
    width: 51px;
    height: 36px;
    
    
}
.enable{
    background-color: $primaryColor;
}
.disable{
    background-color: #707070;
}
@import "../../../../assets/sass/_variables.scss";

.icon-nav-search {
    vertical-align: -0.3667em;
}

.nav-pills-c2 .nav-link {
    padding: 0.6rem 1rem;
}

/* .input-group-prepend {
    position: absolute;
}    */

.content-header .container-fluid.my-4 {
    position: absolute;
    /* z-index: 99; */
    bottom: -50px;
}

.input-group-prepend {
    margin-right: -45px;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    border: 0px solid #ced4da;
    position: relative;
    left: 5px;
    z-index: 5;
}

.spanIcon {
    width: 45px;
    height: 56px;
    background-color: transparent;

    & svg {
        font-size: 1.25rem;
    }
}

div#search-menu {
    -webkit-box-shadow: 0px 8px 8px 2px #00000054;
    box-shadow: 0px 8px 8px 2px #00000054;
}

p.chooice.tt-suggestion.tt-selectable span {
    pointer-events: none;
}

.nav-item.nav-link {
    background-color: #707070;
    color: $primaryColor !important;
    display: flex;
    align-items: center;
    margin-right: 1px;
    border: none !important;
}

.btn-search {
    background-color: $primaryColor !important;
}


.nav-link.active {
    color: $primaryColor !important;
    border-bottom: 0px solid $primaryColor;
    border: none !important;
}

.nav-link:hover {
    border: none !important;
}

.navTabHome {
    //max-height: 252px;
    color: #FFF;

    & h1 {
        font: normal normal 400 48px/24px Segoe UI;
        letter-spacing: 0px;
        padding: 37px 0;
        font-size: calc(1rem + 1.7vw);
    }

    & .nav-item {
        background-color: #FFF;
        transition: none;
        border-top-right-radius: 0.35rem !important;
        border-top-left-radius: 0.35rem !important;

        &:hover {
            border-color: #E7E7E7 !important;
        }

        &.active {
            background-color: #E9EAEE;
            border-radius: 5px !important;

            & span {
                font-size: 1rem;
                border: none !important;
            }
        }

        & span {
            color: $primaryColor;
            font-size: 1rem;
            text-transform: capitalize;
            font-weight: 500;
            padding: 4px 8px;
            border: none !important;
        }
    }

    & h4 {
        color: #666666;
        font-size: 1.5rem;
    }

    & .tab-content {
        & .tab-pane {
            border-bottom-right-radius: 0.35rem !important;
            border-bottom-left-radius: 0.35rem !important;
            border-top-right-radius: 0.35rem !important;
        }

        & .bd-highlight {
            & input.form-control-lg {
                font-size: 1rem;
                padding: 0;
                height: 2.5rem;
            }

            & button {
                height: 100%;

                & span {
                    font-size: 1rem;
                    line-height: 2rem;
                    font-weight: 400;
                }
            }
        }
    }


}

.navTabHome {
    margin: 0px;
}

/* #nav-3.active{
    background-color: $primaryColor;
} */

#nav-tabContent input {
    height: 56px;
}

.tab-search-custom {
    position: relative;
    top: -45px;
    width: 75%;
    margin: auto;
    background-color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
}

.icon-top {
    position: absolute;
    right: 50px;
    top: 140px;
    display: flex;
    align-items: center;

    // & svg.svg-inline--fa.fa-w-18,
    // svg.svg-inline--fa.fa-w-16 {
    //     width: 35px;
    //     height: 25px;

    //     & path {
    //         width: 25px;
    //     }
    // }

    & svg.svg-inline--fa.fa-w-16 {
        margin: 0 10px;
    }
}

.nav-tab-choice {
    width: 100%;

    & span {
        margin: auto;
    }
}

.input-group-append,
.input-group-prepend {
    & button.btn-primary {
        color: #000;
        background-color: transparent;
        border: none;
        border: 1px solid #ced4da;

        &:hover {
            background-color: #F5F5F5;
        }
    }
}

.icon-male svg {
    color: #000;
    position: absolute;
    z-index: 2;
    transform: translate(5px, 18px);
    font-size: 22px;
}

.input-group-append {
    & button.btn-less {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-radius: .35rem;
        height: 100%;
        padding: 5px;

        &:focus {
            box-shadow: unset;
        }
    }
}

.tab-pane .input-group {
    & .rmdp-calendar {
        width: 297px;
    }

    & .rmdp-day-picker {
        display: block !important;
    }
}

.input-group-append {
    & button.btn-more {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-radius: .35rem;
        height: 100%;
        padding: 5px;

        &:focus {
            box-shadow: unset;
        }
    }
}

input#num {
    text-align: end;
    padding: 7px;
    font-size: 20px;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-right: -1px;
    margin-left: 0;
}

.tab-pane {
    & .rmdp-container {
        width: 100%;

        & .rmdp-input {
            width: 100%;
            padding-left: 45px;
        }
    }
}

#nav-2>div>div:nth-child(3)>div>div.rmdp-container>div:nth-child(3)>div>div>div>div:nth-child(2)>div:nth-child(1) {
    color: #000;
}

.tab-pane .form-control:disabled {
    background-color: #FFF;
    opacity: 1;
}

#search-menu {
    padding: 0 !important;
    max-height: 400px;
    overflow: auto;
}

.league-name {
    padding: 15px 15px 0;
}

.chooice.tt-suggestion.tt-selectable {
    margin: 0;
    padding: 10px 25px;

    &:hover {
        background-color: #F4F4F4;
    }
}

.pac-target-input {
    width: 100%;
    padding-left: 45px;
    padding-right: 10px;
    border: 1px solid #ced4da;
    border-radius: 0.35rem !important;

    &:focus {
        border: 1px solid #000;
    }

    &:active {
        border: 1px solid #000;
    }

    &:hover {
        border: 1px solid #000;
    }
}

.react-special-ticket__value-container,
.react-special-ticket__input-container,
.react-special-ticket__control {
    height: 56px;
    margin: 0 !important;
    padding-top: 0 !important;
}

.react-special-ticket__input {
    box-shadow: none !important;
}

.react-special-ticket__menu {
    color: #707070 !important;
}

.react-error__value-container,
.react-error__input-container,
.react-error__control {
    height: 56px;
    margin: 0 !important;
    padding-top: 0 !important;
}

.react-error__control {
    border-color: red !important;
}

.react-error__placeholder,
.react-error__indicator svg path {
    color: red !important;
}

.react-error__indicator-separator {
    background-color: red !important;
}

.react-error__input {
    box-shadow: none !important;
}

.react-error__menu {
    color: #707070 !important;
}

.btn-more {
    padding: 3.5px !important;
}

@media (max-width: 1860px) {
    .btn-less {
        padding: 2.5px !important;
    }

    .btn-more {
        padding: 1px !important;
    }

    .num-people {
        padding: 0 5px;
    }
}

.navTabHome .badge-pill {
    font-size: .9rem !important;
}

@media (max-width: 767px) {
    .tab-search-custom {
        width: 90%;
    }

    .icon-top {
        right: 20px;
        top: 160px;

        & .title-cart {
            font-size: 1rem !important;
        }
    }
}
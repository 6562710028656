.bill-receive-log-table {
    & .table-container {
        & thead tr th:nth-child(1),
        tbody tr td:nth-child(1) {
            // texto
            width: 70%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        & thead tr th:nth-child(2),
        tbody tr td:nth-child(2) {
            // usuario
            width: 15%;
        }

        & thead tr th:nth-child(10),
        tbody tr td:nth-child(10) {
            // data
            width: 15%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}
@import "../../../../assets/sass/_variables.scss";

.info-products {
    color: #858796;

    & #navbar-destino {
        padding: 0;

        & .container {
            padding: 10px;
        }
    }

    & .nav-link {
        font-weight: 500;
    }

    & .nav-link:hover {
        background-color: transparent;
        text-decoration: underline;
        opacity: .8;
    }

    & .jumbotron {
        background: linear-gradient(to top, black 5%, rgba(0, 100, 150, 0) 100%);
    }

    & .nav-pills {
        justify-content: space-around;
    }

    & .bg-white .container {
        background-color: #FFF !important;
    }

    & h1 {
        padding: 140px 25px 100px;
    }

    & .subtitle-product {

        & span,
        h3 {
            color: #FFF !important;
            padding: 0 25px;
        }
    }

    & h3 {
        color: $primaryColor;
        font-weight: 700;
    }

    & .container-fluid {
        background-color: transparent;

        & .text-info-products {
            & p {
                font-size: 1rem;
                color: #858796;
            }

            & h4 {
                color: $primaryColor;
                font-weight: 700;
                text-transform: uppercase;
                font-size: 1rem;
            }

            & h6 {
                color: $primaryColor;
                font-weight: 500;
            }

            & .experience {
                & p {
                    margin-bottom: 0;
                }
            }

            & ul.small.small-info-add li {
                list-style: inside;
            }

            & ul.small li {
                font-size: 90%;
            }
        }
    }
}

#session-options {
    & .panel-dropdown {
        margin: 0 !important;
    }
}

.scroll-product {
    overflow-y: auto;
    padding-left: 60px;
    padding-right: 60px;
}

.price small {
    font-size: 1rem;
    font-weight: 400;
}

.bg-price {
    background-color: #DEE2E6;
}

.nav-link.link-tour.nav-tour {
    padding: 0.5rem 0;

    & .fa-1x {
        font-size: 20px;
    }
}

#navbar-destino {
    & span {
        cursor: pointer;
    }
}

.prod-options {
    & .accordion-item {
        box-shadow: none !important;
    }
}
@import "./../../assets/sass/_variables.scss";

.section-cache {
    padding: 1rem 1.25rem;
}


.modal-cache {
    & .modal-content {
        padding: 0;
        min-height: 378px;

        & .modal-header {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: space-between;
            padding: 1rem;
            position: unset;
            //border-bottom: 1px solid #dee2e6;
            color: $primaryColor;
        }

        & .modal-body {
            padding: 0 25px !important;
            margin: 2rem 4rem !important;
            display: flex !important;
            flex-direction: column !important;
            justify-content: center !important;

            & p {
                font-size: 1rem !important;
            }

        }

        & .modal-footer {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: end;
            padding: 1rem;
            position: unset;
            //border-top: 1px solid #dee2e6;
        }

        & h3 {
            margin-bottom: 0;
        }
    }
}
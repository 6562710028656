@import '../../../assets/sass/_variables.scss';

.border-right {
    border-right: 1px solid #e3e6f0 !important;
}

.bg-analise {
    background-color: $primaryColor;
    color: #FFF;
    border-radius: 10px;
    padding: 5px 10px;
    text-align: center;
}

.bg-ativo {
    background-color: #7DD4AB;
    color: #FFF;
    border-radius: 10px;
    padding: 5px 10px;
    text-align: center;
}

.bg-inativo {
    background-color: #E27376;
    color: #FFF;
    border-radius: 10px;
    padding: 5px 10px;
    text-align: center;
}

.bg-blue {
    color:#fff;
    background-color: #2642f4;
    border-radius: 10px;
    padding: 5px 10px;
    text-align: center;
}
  
.bg-lightblue {
    color:#fff;
    background-color: #4960f5;
    border-radius: 10px;
    padding: 5px 10px;
    text-align: center;
}

.bg-yellow {
    color: #fff;
    background-color: #f6c23e;
    border-radius: 10px;
    padding: 5px 10px;
    text-align: center;
}
@import "./../../assets/sass/_variables.scss";


.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 4px solid $primaryColor;
    max-height: 116px;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
}

.header h1{
    font-size: 1.4rem;
    max-width: 150px;
    margin: 17px 0 17px 20px;
    text-align: left;
    color: #707070;
}

.header .header-search{
    margin-right: 20px;
}

.header .input-search{
    min-width: 350px;
}

.header .reservation{
    padding-right: 20px;
    border-right: 1px solid #ccc;
}

.header button.new-reservation{
    background-color: $primaryColor;
    color: white;
    border-right: 1px solid #ccc;
    display: flex;
    align-items: center;
    padding: 0 15px;
    min-height: 50px;
}

.header .icon-nav-search.glass{
    margin: 0 40px;
}

.header .profile{
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.header .user-name{
    margin-right: 10px;
}

.dropdown-profile {
    & a {
        text-decoration: none;
        color: #000;
        &.disabled {
            pointer-events: none;
            cursor: default;            
            & p {
                opacity: 0.5;
            }
          }
    }
    & p {
        margin: 0;
        &:nth-child(1) {
            margin-bottom: 5px;
        }
    }
}

.logo-main {
    padding: 15px;
}

.titleHeader{
    max-width: 150px;
    font-size: 18px;
    margin-bottom: 0px !important;
    padding: 10px;
    padding-left: 15px;
    font-weight: 400;
}

.divisor {
    border: 1px solid #B7B7B7;
    margin: 15px 5px;
}
    

.menu-profile:after{
    content: none;
}

.menu-profile, .menu-profile:focus, .menu-profile:hover, .menu-profile:active, .show > .menu-profile.dropdown-toggle:focus {
    background-color: transparent !important;
    color: $primaryColor !important;
    border: 0px;
    box-shadow: none !important;
}

ul#accordionSidebar {
    position: fixed;
    background-color: #fff;
}

#offcanvasNavbar {
    width: 100px;
}

ul#accordionSidebar {
    display: none;
}

body.menu-show #root ul#accordionSidebar {
    display: flex;
}

body.menu-show .contentSIG {
    // transform: translateX(var(--off-canvas-panel-width));
    width: calc(100% - var(--off-canvas-panel-width));
    transition: transform 0.3s ease-in-out;
    order: 1;
    margin-left: 100px;
}


.offcanvas{
    position: initial !important;
    transition: none !important;
}

.card-title{
    color: #A1ADC1;
    font-weight: 600;
    font-size: 10px;
}


// body.menu-show {
//     display: flex;
//     padding-right: 0px !important;
// }

:root {
    --off-canvas-panel-width: 100px;
    --off-canvas-panel-width-opposite: 0px; 
    --off-canvas-animation-duration: 0.4s;
  }


@media (max-width: 1400px){
    body.menu-show .contentSIG {
        width: calc(100% - var(--off-canvas-panel-width));
        transition: transform 0.3s ease-in-out;
        order: 1;
        margin-left: 65px;
    }

    :root {
        --off-canvas-panel-width: 65px;
        --off-canvas-panel-width-opposite: 0px; 
        --off-canvas-animation-duration: 0.4s;
      }
}
@import "../../../assets/sass/_variables.scss";

.newUsersModal .table-container{
    width: 100%;
    box-shadow: 0 0 1em #ccc;
}

.newUsersModal .table-title h3 {
    text-align: left;
    font-size: 20px;
    color: $primaryColor;
}

.newUsersModal table {
    margin: 0 auto 25px auto;
    max-width: 100%;
}

.newUsersModal {
    & tr th {
        background-color: $primaryColor;
        color: white;
        font-weight: 600;
    }

    & .center-table {
        padding: 10px 0;
    }

    & td {
        vertical-align: middle;
    }

    & thead {
        & tr th {
            padding: 10px !important;
        }
    }
}

.table-title{
    padding: 1rem 1.25rem;
}

.newUsersModal .dropstart .dropdown-toggle::before{
    display: none;
}

.newUsersModal form > div.mb-3.row{
    align-items: flex-end;
}

.newUsersModal button{
    background-color: $primaryColor;
    color: white;
    border: 0px;
    max-width: 31%;
    max-height: 40px;
}

.show > .btn-light.dropdown-toggle:focus{
    box-shadow: none;
}

.options-table .dropdown-toggle::after{
    content: none;
}

.options-table .btn-light:hover{
    color: $secondaryColor;
    background-color: transparent;
    border-color: none;
}

.newUsersModal #pageDropDown{
    background-color: $primaryColor;
    color: white;
    margin-left: 2%;
    max-width: 70px;
}

.newUsersModal .pagination.react-bootstrap-table-page-btns-ul{
    margin-right: 2%;
}

.newUsersModal .page-item .page-link{
    color: $primaryColor;
}

.newUsersModal .page-item.active .page-link{
    background-color: $primaryColor;
    border-color: $primaryColor;
    color: white;
}

.newUsersModal .row.react-bootstrap-table-pagination {
    padding: 0 0 12px;
}
.voucher-download {
    background-color: var(--primaryShadow);

    & .modal-header {
        position: unset;
        border-bottom: unset;
        right: unset;
        padding: 0px;
        z-index: unset;
        width: 100%;
        align-items: flex-start;
    }

    & .modal-content {
        max-width: 912px;
        width: 100% !important;
        height: 90vh !important;
        margin: auto;
        padding: 2rem !important;
    }

    & .btn-primary {
        width: 150px;
    }
}

.table-voucher {
    & input[type="checkbox"] {
        cursor: pointer;
    }
}

.bg-voucher-download {
    height: 100%;
    overflow: auto;

    /* Estilo para la barra de desplazamiento */
    &::-webkit-scrollbar {
        width: 6px;
        border-radius: 6px;
    }

    /* Estilo para el thumb (barra de desplazamiento) */
    &::-webkit-scrollbar-thumb {
        background-color: #FF6600;
        border-radius: 6px;
        box-shadow: 0 2px 4px #FF660020;
    }

    /* Estilo para el thumb cuando se desplaza */
    &::-webkit-scrollbar-thumb:hover {
        background-color: #FF6600;
        box-shadow: 0 2px 4px #FF660020;
        cursor: pointer;
    }

    &::-webkit-scrollbar-track {
        background-color: #FF660020;
        border-radius: 6px;
    }

    &::-webkit-scrollbar-button:start:decrement {
        display: none;
    }

    &::-webkit-scrollbar-button:end:increment {
        display: none;
    }

    & .table-default {
        overflow: auto;
        position: relative;

        & thead tr th:nth-child(1),
        tbody tr td:nth-child(1) {
            width: 7.5%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        & thead tr th:nth-child(2),
        tbody tr td:nth-child(2) {
            text-align: left;
            width: 12.5%;
        }

        & thead tr th:nth-child(3),
        tbody tr td:nth-child(3) {
            text-align: left;
            width: 20%;
            word-wrap: break-word;
        }

        & thead tr th:nth-child(4),
        tbody tr td:nth-child(4) {
            width: 50%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        & .react-bootstrap-table tr {
            min-height: 52px !important;
        }

        &.loading {
            & tbody tr {
                border: 1px solid rgb(206, 206, 206) !important;
            }

            & .row-loading {
                height: 100%;
                border-radius: 5px;
            }

            & .selection-cell {
                display: none;
            }

            & .selection-cell-header input {
                display: none;
            }
        }
    }
}

.blackout-load {
    width: 18px;
    height: 18px;
    margin: 0 auto;
    border: solid 3px #6e707e;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-name: rotate;
    animation-duration: 1.0s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.text-forgot-pass {
    color: #858796;
}

.title-forgot-pass {
    color: #666;
}

.link-forgot-pass {
    & a {
        text-decoration: none;

        &:hover {
            color: #224abe;
            text-decoration: underline;
        }
    }
}

#new-pass {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.btn-new-pass {
    margin: 40px 0;
}

.link-new-pass {
    margin: 20px 0 30px;
}

.valign-new-pass p {
    margin-bottom: 10px !important;
}

.link-new-pass {
    & a {
        text-decoration: none;

        &:hover {
            color: #224abe;
            text-decoration: underline;
        }
    }
}
.bill-receive-bills-table {
    & .table-container {
        & thead tr th:nth-child(1),
        tbody tr td:nth-child(1) { // data
            width: 11%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        & thead tr th:nth-child(2),
        tbody tr td:nth-child(2) { // forma pagamento
            width: 13%;
        }

        & thead tr th:nth-child(3),
        tbody tr td:nth-child(3) { // valor
            width: 8%;
        }

        & thead tr th:nth-child(4),
        tbody tr td:nth-child(4) { // mdr
            width: 6%;
        }

        & thead tr th:nth-child(5),
        tbody tr td:nth-child(5) { // operadora
            width: 13%;
        }

        & thead tr th:nth-child(6),
        tbody tr td:nth-child(6) { // nsu
            width: 11%;
        }

        & thead tr th:nth-child(7),
        tbody tr td:nth-child(7) { // venc
            width: 10%;
        }

        & thead tr th:nth-child(8),
        tbody tr td:nth-child(8) { // obs
            width: 15%;
        }

        & thead tr th:nth-child(9),
        tbody tr td:nth-child(9) { // status
            width: 4%;
            text-align: center;
        }

        & thead tr th:nth-child(10),
        tbody tr td:nth-child(10) { // acoes
            width: 5%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

    }
}
@import "../../assets/sass/_variables.scss";

label {
    color: #707070;
    font-weight: 500;
    font-size: 16px;
}

input[type="file"] { 
    display: none; 
}

.btn-file {
    border: 1px solid #D1D3E2;
    width: 110px;
    border-radius: 0.25rem;
    color: #707070;
    font-size: 14px;
    font-weight: 500;
    height: 38px;
    text-align: center;
    line-height: 34px;
    cursor: pointer;
    margin-right: 10px;
    &:hover {
        opacity: .7;
    }
}

.bg-white .accordion .accordion-item {
    box-shadow: 0px 3px 6px #00000029 !important;
    border: 1px solid #7070704A;
    & input, select {
        height: 32px;
        line-height: 1rem;
    }
    & input[type="radio"] {
        height: 1rem;
    }  
}

button.accordion-button {
    height: 72px;
}

.form-check-input:valid,
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    color: $primaryColor;
}

.was-validated .form-check-input:valid ~ .form-check-label {
    color: #707070;
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
    border-color: #707070;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: $primaryColor;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus,
.form-check-input:focus {
    box-shadow: none;
}

.accordion-custom {
    & .accordion-button {
        color: $primaryColor;
        font-weight: 500;
        font-size: 18px;
    }
    & .accordion-button:not(.collapsed) {
        background-color: #FFF;        
        box-shadow: unset;
        color: $primaryColor;
        font-weight: 500;
    }
    & .accordion-button:focus {
        z-index: 3;
        border: none;
        outline: 0;
        box-shadow: unset;
    }
}

.radioPersonal {
    border: 1px solid #F2F2F2;
    width: 340px;
    & .form-check-label {
        font-size: 1rem;
        font-weight: 500;
        color: #707070;
    }
    & small {
        padding: 0 0 0 24px;
        font-size: .8rem;
    }
}

.form-button {
    width: 180px;
    text-transform: uppercase;
}

.form-button.cancel {
    width: 180px;
    text-transform: uppercase;
    background-color: #ccc;
    color: black;
    border: none;
    margin: 0 10px;
}

.accordion-body .container-fluid, .accordion .container-fluid{
    background-color: transparent;
}

.accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
}

.accordion-button:focus {
    z-index: 3;
    border: none;
    outline: 0;
    box-shadow: unset;
}

.logo-info-company {
    border: 2px solid #707070;
    border-radius: 50%;
    width: 220px;
    height: 220px;
    & img {
        width: 100%;
        height: 100%;
        padding: 10px;
    }
}

.accordion .card{
    border: none;
}

.accordion {
    width: 100%;
}

.accordion .card-header {
    padding: 0px;
    border-bottom: none;
}

.accordion .card {
    width: 100%;
    box-shadow: 0px 2px 0.4em #ccc;
}

.suppliers-filter,
.partners-filter,
.accounts-filter,
.merchants-filter,
.merchantflags-filter {
    & .accordion-header {
        & svg path {
            fill: $primaryColor;
        }
        & span {
            color: $primaryColor;
        }
    }
}

.prod-options {
    & .accordion-item:first-of-type .accordion-button.collapsed {
        border-radius: 0 0 10px 0 !important;
    }

    & .accordion-item:first-of-type .accordion-button {
        border-radius: 0 0 0 0 !important;
    }
}







@import "_variables.scss";

.form-add-contact {
    & input.form-control {
        border: 1px solid #D1D3E2;
    }

    & label {
        font-size: 16px;
        font-weight: 500;
        color: #707070;
    }

    & .modal-body {
        padding: 0 !important;
    }
}


.bg-modal-contact {
    background-color: #FFF;
    height: 100%;

    & h5 {
        font-size: 18px;
    }
}

.buttons-modal button {
    width: auto;
    font-weight: 500;
    font-size: 20px;
    margin-top: 20px;
    /*     &:nth-child(1) {
        background-color: #FFF;
        color: #707070;
        border-color: #707070;
    } */
}

// Modal New User
.modal-new-user,
.modal-add-contact {
    & .modal-content {
        width: 565px;
        height: auto !important;
    }
}

.contact-spacing {
    margin: 150px 0 150px;
}

// Modal Sucess

.modal-backdrop {
    background-color: #163264;
}

.modal-backdrop.show {
    opacity: 0.39;
}

.modal-custom {
    & .modal-lg {
        max-width: 100%;
        justify-content: center;
    }

    & .modal-body {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        flex-direction: column;

        & h5 {
            font-size: 26px;
            color: #163264;
        }

        & p {
            font-size: 20px;
            color: #707070;
        }
    }

    & .modal-content {
        width: 565px;
        height: auto;
        border-radius: 0;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.20) 0px 3px 5px;
        padding: 60px;
    }

    & .modal-body {
        margin: 0;
        border-top: none;

        & button {
            font-size: 20px;
            width: 180px;
            background-color: #FFF;
            color: #707070;
            border: 1px solid #707070;
            border: none !important;
            margin: 0px !important;
        }
    }

    &.modalAuth {
        & .modal-content {
            margin: auto;
        }

        & .modal-body {
            display: block;
            padding: 4rem 3rem;

            & hr {
                display: none;
            }
        }
    }
}

.suppliers-table button {
    margin: auto;
}

.btn-sucess {
    & button {
        color: #fff;
        background-color: #163264;
        border-color: #163264;
        text-transform: uppercase;
        width: 180px;

        &:hover {
            color: #fff;
            background-color: #132b55;
            border-color: #122850;
        }
    }
}

.sucess-pos,
.error-pos {
    display: flex;
    flex-direction: column;
    margin: 6.5rem auto;
    height: 100%;
    justify-content: center;
    margin: 0;
}

.modal-prod-options {
    & button.accordion-button {
        font-size: 1rem;
        font-weight: 700;
        width: 100%;
        background-color: #E5E5E5;
        color: #707070;
        border: none;
        height: 48px;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    & .accordion-collapse {
        background-color: #FFF;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    & .accordion-item:last-of-type {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    & .accordion-item:first-of-type {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    & .accordion-item {
        border-top: 3px solid rgba(0, 0, 0, 0.125);
        border-bottom: 5px solid rgba(0, 0, 0, 0.125);
        border-left: none;
        border-right: none;
    }

    & .accordion-body {
        padding: 0;
    }

    & .session-not-yes {
        & p {
            font-size: .8rem !important;
        }
    }
}

.top-info-close {
    background-color: #163264;
    color: #FFF;

    & .modal-header {
        padding: 15px 25px;

        &.btn-close {
            padding: 0.25em 0.25em;
            margin: 0;
        }
    }

    & button {
        font-size: unset !important;
        width: unset !important;
        background-color: unset !important;
        color: #FFF !important;
        border: unset !important;
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(292deg) brightness(102%) contrast(102%);
    }

    & .modal-title.h4 {
        font-size: 1rem;
    }
}

.modal-products-info {
    & .modal-content {
        width: 1280px !important;
        max-height: calc(100vh - 3.5rem);
        padding: 0;
        margin: auto;
    }
}

.modal-header {
    border-bottom: none;
    position: absolute;
    right: 0;
    padding: 10px 60px 10px 0;
    z-index: 1;
}

.modal-footer {
    border-top: none;
}

.modal-large {
    & .modal-content {
        width: 950px;
        height: auto !important;
        padding: 25px;

        & .modal-header {
            padding: 0;
            position: relative;
            color: $primaryColor;

            & h3 {
                margin-bottom: 0;
            }

            & .btn-close {
                margin-bottom: 0;
            }
        }
    }
}


.modal-pos-tax {
    & .modal-content {
        padding: 25px;

        & .modal-header {
            width: 100%;
            padding: 0 25px !important;
            top: 25px;
            color: $primaryColor;
        }

        & .modal-footer {
            padding: 0;
        }

        & h3 {
            margin-bottom: 0;
        }

        & hr {
            margin: 50px 0 30px !important;
        }
    }
}

.modal-content-note {
    padding: 25px 0 25px ;
    border-bottom: 1px solid #ced4da;
    & h5 {
        font-size: 1rem;
    }
    & p, b {
        font-size: .9rem;
    }
    &.single-content-note {
        padding-bottom: 40px;
        border-bottom: none;
        & p, b {
            font-size: .8rem;
        }
    }
}

.modal-session-payment {
    border: 1px solid #ced4da;
    & .new-payment {
        padding: 10px 25px;
        & h5 {
            font-size: 1rem;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
        }
        & label, input, button {
            font-size: .9rem;
        }
    }
}

/* .modal-confirm {
    background-color: #00000030;
} */

.modal-confirm {
    & .modal-content {
        max-width: 520px;
        height: 660px;
        margin: auto;

        & .modal-body {
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 540px;
        }
    }

    & .btn {
        min-width: 90px
    }
}
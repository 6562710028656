@import '../../assets/sass/_variables.scss';

.single-session{
    & .suppliers {
        padding-bottom: 1.25rem;
        border-bottom: 1px solid #70707050;
        & span {
            font-weight: 500;
        }
    }
    p{
        margin: 0;
    }

    .pax{
        margin: 20px 0 50px;

        .bg-pendente{
            max-width: 150px;
            width: 100%;
            background-color: #E27376;
            color: #FFF;
            border-radius: 10px;
            padding: 2px 10px;
            text-align: center;
        }
        
        .total-pax{
            display: flex;
            align-items: center;
            padding: 0 50px;
            //font-size: .875rem;
            font-weight: 400;
        }
        
        .pax-units{
            display: flex;
            align-items: center;
            padding: 0 50px;
            border-left: 1px solid #70707050;
            //font-size: .875rem;
            font-weight: 400;
        }
    }

    .recursos{

        .circled{
            display: flex;
            justify-content: center;
            align-items: center;
            width: unset;
            border: 1px solid #B4B4B4;
            border-radius: 50%;
            padding: 10px;
            width: 3.25rem;
            height: 3.25rem;
            & svg {
                color: #B4B4B4;
            }
        }

        .forms{
            display: flex;
            max-width: calc(100% - 100px);
            justify-content: space-between;

            .tipo{
                display: block;
                width: 100%;
                padding: 0.375rem 2.25rem 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
                background-color: #fff;
                background-repeat: no-repeat;
                background-position: right 0.75rem center;
                background-size: 16px 12px;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                appearance: none;
                text-transform: uppercase;
            }

            .action-btn{

                button{
                    background-color: transparent;
                    border-color: transparent;
                    color: $primaryColor;
                    
                    &::before{
                        display: none;
                        
                    }
                }
            }
        }

        .new-resource{
            button{
                background-color: transparent;
                border: 1px solid #707070;
                border-radius: 5px;
                color: #707070;
                font-weight: 500;
                padding: 2.5px 40px;
                &:hover {
                    color: #FFF;
                    background-color: #707070;
                }
            }
        }
        
    }
}

.manifest-label {
    color: #707070;
    font-weight: 600;
    font-size: 1rem;
}

.icon-notice {
    cursor: pointer;
    & svg {
        color: $primaryColor;
        &:hover {
            opacity: 0.7;
        }
    }
}

.resource-type {
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
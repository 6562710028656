.banner-home {
    width: 100%;
    height: 300px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    & h2 {
        color: #FFF;
        font-weight: 700;
        font-size: 54px;
        line-height: 60px;
    }

    .content-banner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 100px;
    }
}

@media(max-width: 767px) {
    .banner-home {
        width: 100%;
        height: 370px;    

        & h2 {
            color: #FFF;
            font-weight: 700;
            font-size: 34px;
            line-height: 40px;
        }

        & .content-banner{
            padding-left: 20px;
        }
    }
}

.bg-home-size {
    height: calc(100% - 225px/2);
}
@import '../../../../assets/sass/_variables.scss';

.merchants-table {
    & .table-container {
        & .table {

            & thead tr th:nth-child(1),
            tbody tr td:nth-child(1) {
                width: 20%;
                padding-left: 20px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            & thead tr th:nth-child(2),
            tbody tr td:nth-child(2) {
                width: 20%;
            }

            & thead tr th:nth-child(3),
            tbody tr td:nth-child(3) {
                width: 10%;
            }

            & thead tr th:nth-child(4),
            tbody tr td:nth-child(4) {
                width: 12.5%;
            }

            & thead tr th:nth-child(5),
            tbody tr td:nth-child(5) {
                width: 15%;
            }

            & thead tr th:nth-child(6),
            tbody tr td:nth-child(6) {
                width: 10%;
            }

            & thead tr th:nth-child(7),
            tbody tr td:nth-child(7) {
                width: 10%;
            }

            & thead tr th:nth-child(8),
            tbody tr td:nth-child(8) {
                width: 2.5%;
                text-align: center;
            }
        }
    }
}
@import "../../assets/sass/variables";

.panel-hours {
  padding: 15px 4px 10px 4px !important;
}

.hours-options {
  border: 1px solid #707070;
  text-align: center;
  padding: 0.375rem;
  border-radius: 0.25rem;
  line-height: 0.875rem;
  cursor: pointer;

  &:hover {
    background-color: $primaryColor;
    border-color: $primaryColor;
    color: #ffffff !important;

    & p,
    small,
    span {
      color: #ffffff !important;
    }
  }

  & p {
    font-size: 1rem !important;
    font-weight: 600;
    margin-bottom: 0;
  }

  & small {
    font-size: 0.5rem !important;
    font-weight: 400;
    line-height: 0;
  }

  & span {
    display: block;
    font-size: 0.5rem !important;
    font-weight: 400;
    line-height: 1.5;
  }
}

.hours-title {
  text-transform: uppercase;
  font-size: 0.75rem;
  padding-left: 0.25rem;
}

.size-icon-hours {
  font-size: 1rem !important;
  width: 1rem;
  position: absolute;
}

.panel-dropdown {
  & .load {
    width: 20px;
    height: 20px;
    margin: 0 auto;
    border: solid 3px $primaryColor;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@import "../../../../assets/sass/_variables.scss";

.modal-large-custom {
    & .modal-header {
        & .rmdp-container {
            width: auto !important;
            margin-left: 20px;
        }
    }

    & .modal-content {
        width: 1280px;
        height: auto !important;
        padding: 25px;

        & .table-container {
            border: none;
            box-shadow: none !important;


            & .btn-expiration {
                display: flex;
                justify-content: end;

                & button {
                    background-color: $primaryColor !important;
                    width: 180px !important;
                    text-transform: uppercase;
                    margin-top: 20px;
                }
            }

            & .table {
                padding: 0;

                & thead {
                    & tr {
                        background-color: #FFF;
                    }
                }

                & tr {
                    background-color: #EEEEEE;
                }

                & thead tr th:nth-child(1),
                tbody tr td:nth-child(1) {
                    width: 4%;
                    padding-left: 20px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                & thead tr th:nth-child(2),
                tbody tr td:nth-child(2) {
                    width: 10%;
                }

                & thead tr th:nth-child(3),
                tbody tr td:nth-child(3) {
                    width: 10%;
                }

                & thead tr th:nth-child(4),
                tbody tr td:nth-child(4) {
                    width: 26%;
                }

                & thead tr th:nth-child(5),
                tbody tr td:nth-child(5) {
                    width: 20%;
                }

                & thead tr th:nth-child(6),
                tbody tr td:nth-child(6) {
                    width: 14%;
                }

                & thead tr th:nth-child(7),
                tbody tr td:nth-child(7) {
                    width: 6%;
                }

                & thead tr th:nth-child(8),
                tbody tr td:nth-child(8) {
                    width: 10%;
                    text-align: center;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
        }
    }
}
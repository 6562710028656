@import "../../../../../assets//sass/variables-caracol";

.modal-log {
    & .modal-lg {
        max-width: 1024px;
        & .modal-content {
            width: 100% !important;
            height: 90vh !important;
            padding: 25px !important;

            & .modal-header {
                position: initial !important;
                padding: 0 0 1rem;
                & h3 {
                    color: $primaryColor;
                    margin-bottom: 0;
                }
            }
            & .modal-body {
                overflow-y: auto;
                padding: 3rem 2rem 4rem 1rem;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }
}
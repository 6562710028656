@import "../../../assets/sass/_variables.scss";

step-labels {
    & span {
        position: relative;
        font-weight: 600;
        &:nth-child(1) {
            left: -20px;
        }
        &:nth-child(2) {
            left: -50px;
        }
        &:nth-child(3) {
            left: -70px;
        }
        &:nth-child(4) {
            left: -20px;
        }
        &:nth-child(5) {
            left: 30px;
        }
        &:nth-child(6) {
            left: 23px;
        }
    }
}

.steps .step.complete {
    background-color: $primaryColor;
    & .complete {
        color: $primaryColor;
    }
}

.step-labels span {
    font-size: 16px;
    color: #707070;
}

.steps .stepline {
    width: calc(50% - 45px);
    background-color: #707070;
    height: 5px;
}

.steps .step {
    background-color: #707070;
    & span {
        position: absolute;
        transform: translateX(-50%);
        color: #707070;
        width: 250px;
    }
}

.step-labels .complete {
    color: $primaryColor !important;
    font-weight: 600;
}

.steps .stepline.complete {
    background-color: $primaryColor;
}

@media(max-width: 1600px) {
/*     .steps .step {
        & span {
            width: 95px;
            line-height: 1.5rem;
            margin-top: 5px;
        }
    }
 */
    .suppliers-guide.steps-guide.container,
    .partners-guide.container {
        width: 80% !important;
    }
}

.back-btn{
    margin-right: 20px;
    background-color: transparent;
    color: $primaryColor;
}
@import "../../../../assets/sass/_variables.scss";

.event-details .row>* {
    padding-left: 0px;
    padding-right: 0px;
}

.divisor-custom {
    border-right: 1px solid #dbdbdb;
    min-width: 125px !important;
}

.options-reservations {
    width: auto !important;
    font-size: 16px !important;
    padding: 0px !important;
    background-color: transparent !important;
    border: none !important;
}

.options-reservations:after {
    content: '';
    display: none;
}

.event-details {

    .form-control.calendar-input {
        padding: 0.375rem 1.75rem;
        max-height: 34px;
        padding-right: 10px !important;
    }

    .modal-header {
        position: unset;
        border-bottom: unset;
        right: unset;
        padding: 0px;
        z-index: unset;
        width: 100%;
        align-items: flex-start;

        background-position: center center;
        padding: 2rem 1rem 1rem 2rem;
        height: 280px;
        color: #FFFFFF;

        .title-reservation {
            font-weight: 700;
        }

        &::after {
            content: "";
            background-color: #00000045;
            width: 100%;
            height: 280px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .modal-body {
        padding: 0 !important;
    }

    .col-md-12 {
        padding-right: 10px;
    }

    .modal-content {
        padding: 0 !important;
    }

    .buttons-options {
        display: flex;

        & svg path {
            color: #FFFFFF;
        }

        & .text-danger {

            &:hover {
                background-color: #e9eaee;
            }
        }
    }

    .close {
        background-color: transparent !important;
        border: unset !important;
        font-size: 25px !important;
        width: unset !important;
    }

    .modal-dialog.modal-lg {
        max-width: 712px;
    }

    .modal-content {
        width: 100% !important;
        height: 82vh !important;

        .modal-body {
            height: 90vh !important;
            overflow: auto;
            justify-content: flex-start !important;
        }
    }


    .details-reservations-submit-button {
        background-color: $primaryColor !important;
        color: #fff !important;
        min-height: 38px;
        min-width: 125px;
    }

    span.subtitle-modal {
        font-size: 14px;
        text-transform: uppercase;
        display: flex;
    }

    span.response-modal {
        font-size: 14px;
    }

    .icon-code {
        padding: 12px 4px;
        border-radius: 50%;
        border: 1px solid;
        width: 50px;
        height: 50px;
    }
}

.event-details-edit-response {
    .modal-header {
        position: unset;
        border-bottom: unset;
        right: unset;
        z-index: unset;
    }

    .close {
        background-color: transparent !important;
        border: unset !important;
        font-size: 25px !important;
        width: unset !important;
    }

    .modal-dialog.modal-lg {
        max-width: 1024px;
    }

    .modal-content {
        width: 565px;
        height: 665px !important;
        border-radius: 0;
        border: none;
        padding: 60px;
        margin: auto;
    }

    .load {
        width: 100px;
        height: 100px;
        margin: 0 auto;
        border: solid 5px $primaryColor;
        border-radius: 50%;
        border-right-color: transparent;
        border-bottom-color: transparent;
        -webkit-transition: all 0.5s ease-in;
        -webkit-animation-name: rotate;
        -webkit-animation-duration: 1.0s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        transition: all 0.5s ease-in;
        animation-name: rotate;
        animation-duration: 1.0s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    .details-reservations-submit-button {
        background-color: $primaryColor !important;
        color: #fff !important;
    }
}


.bg-modal-custom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: #1633647a;

    & .modal-confirm {
        position: relative;
        top: 50px;
    }
}

.bg-modal-custom.modal-cancel-reservation {
    z-index: 999999;
}

.modal-close {
    opacity: 0;
    display: none;
    transition: ease-in 0.3s;
}

.code-waiting .icon-code {
    border: 1px solid $info;
}

.code-used .icon-code {
    border: 1px solid $success;
}

.code-waiting,
.code-used {
    border-bottom: 1px solid #f2f2f2;
    padding: 20px;
}

.text-strong {
    font-weight: 600;
}

.event-details .fa-chevron-down {
    display: none;
}

.load-big {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border: solid 5px $primaryColor;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1.0s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.event-details .badge {
    width: 100px;
    padding: 5px 10px;
    border-radius: 5px;
}

.event-details .bg-blue {
    background-color: #007bff;
    width: 100px;
}

.number-of-people {
    //height: 300px;
    //border: 1px solid black;
    height: 100%;
    max-height: 34px;
    border-radius: 3px !important;
    border: 1px solid #d2d8dd;
    box-shadow: 0px 4px 2.5px 0px #70707025;
    margin-right: 10px;
    padding: 3px 15px;

    #wrapper {
        position: relative;

        .absolute {
            position: absolute;
            top: 15px;
            right: -25px;
            width: 234px;
        }
    }

    .qtyButtons {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .qtyButtons label {
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        padding-right: 5px;
        display: flex;
        flex-direction: column;
        flex: 1;
        color: $primaryColor;
    }

    .qtyButtons label small {
        color: #495057 !important;
    }

    .price-dropdown {
        line-height: 8px;
        margin-bottom: 10px;
    }

    .qtyInc,
    .qtyDec {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        background-color: #f2f2f2;
        -webkit-text-stroke: 1px #f2f2f2;
        color: #333;
        display: inline-block;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
    }

    .qtyButtons input {
        outline: 0;
        font-size: 16px;
        font-size: 1rem;
        text-align: center;
        width: 30px;
        height: 36px !important;
        color: $primaryColor;
        line-height: 36px;
        margin: 0 !important;
        padding: 0 5px !important;
        border: none;
        box-shadow: none;
        pointer-events: none;
        display: inline-block;
        border: none !important;
    }

}

.access-code .dropstart .dropdown-toggle::before {
    display: none;
}

.border-right {
    border-right: 1px solid rgba(0, 0, 0, .1);
}

.badge.grey {
    background-color: #6c757d !important;
}

.access-code-reload-btn {
    cursor: pointer;
}

.response-modal-custom {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    padding-right: 10px;
}

@media (max-width: 1366px) {
    .event-details {
        .modal-header {
            height: 190px;

            &::after {
                content: "";
                height: 190px;
            }
        }

        .modal-content {
            width: 100% !important;
            height: 90vh !important;
        }
    }
}
.thumbnail-slider-wrap {
    margin-top: 15px;
    height: 85px;
  }
  .thumbnail-slider-wrap .slick-track .slick-slide {
    text-align: center;
    //width: 92px !important;
    margin-right: 9px;
  }
  .thumbnail-slider-wrap .slick-track .slick-slide img {
    width: 100%;    
  }

  .slick-next,
  .slick-prev {
    z-index: 1;
    background-color: #FFF !important;
    border: 2px solid #F2F2F2 !important;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    transition: all .2s;
    top: 45%;
    &:hover {
      background-color: #00632D !important;
    }
    &::before {
      border-radius: 50px;
      display: block;
    }
    &:hover:before {
      color: #FFF !important;
    }
  }

/*   .slick-next:before
  {
      content: url(../../../assets/icons/arrow-right.svg);
      font-size: 30px;
      font-weight: 700;
      line-height: 0;
      color: #e2e2e2 !important;
      margin-left: 3px;
  }

  .slick-prev:before
  {
      content: url(../../../assets/icons/arrow-left.svg);
      font-size: 30px;
      font-weight: 700;
      line-height: 0;
      color: #e2e2e2 !important;
      margin-right: 3px;
  } */

  .slick-next {
    right: -10px;
  }

  .slick-prev {
    left: -10px;
  }
//   .thumbnail-slider-wrap .slick-track {
//     width: 100% !important;
//   }

.destino .thumbnail-slider-wrap {
  & .slick-next, .slick-prev {
    display: none !important;
  }
}

.tourSlider {
  overflow: hidden;
}

.slick-list {
  padding: 0;
}

.thumbnail-slider-wrap {
  margin-top: 0;
  height: 85px;
}

.slick-slide img{
  max-height: 625px !important;  
}
.list-type-products{
    .card{
        box-shadow: 0px 3px 6px #00000029 !important;
        border: 1px solid #7070704A;
        padding:  16px 20px;
    }
}


.LinkProductFound-table {
    & .react-bootstrap-table tbody tr {
        border-color: #cecece;
        border-right: 1px solid #cecece;
        border-top: 1px solid #cecece;
        border-bottom: 1px solid #cecece;
        border-left: 1px solid #cecece;
        transition: .3s;
        

        &:hover {
            border-color: #cecece;
        }
    }
    
    & .table-container {
        & thead tr th:nth-child(1),
        tbody tr td:nth-child(1) {
            width: 10%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;           
        }


        & thead tr th:nth-child(2),
        tbody tr td:nth-child(2) {
            width: 90%;
            text-align: left;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

}

.modal-edit-coupom .modal-content {
    width: 950px;
}


.merchants-filter .radioPersonal {
    border: 1px solid;
    padding: 0 0 0 30px;
}

.table > :not(:first-child){
    border-top: none;
}

.ranger-calendar .rmdp-container {
    width: 100%;
}

.ranger-calendar .form-control[readonly]{
    background-color: transparent;
}
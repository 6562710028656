.coupons-table {
    & .react-bootstrap-table tbody tr {
        border-color: #cecece;
        border-right: 1px solid #cecece;
        border-top: 1px solid #cecece;
        border-bottom: 1px solid #cecece;
        border-left: 1px solid #cecece;
        transition: .3s;

        &:hover {
            border-color: #cecece;
        }
    }
    
    & .table-container {
        & thead tr th:nth-child(1),
        tbody tr td:nth-child(1) {
            width: 15%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        & thead tr th:nth-child(2),
        tbody tr td:nth-child(2) {
            width: 10%;
        }

        & thead tr th:nth-child(3),
        tbody tr td:nth-child(3) {
            width: 10%;
        }

        & thead tr th:nth-child(4),
        tbody tr td:nth-child(4) {
            width: 45%;
        }

        & thead tr th:nth-child(5),
        tbody tr td:nth-child(5) {
            width: 15%;
        }

        & thead tr th:nth-child(6),
        tbody tr td:nth-child(6) {
            width: 5%;
            text-align: center;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

}

.modal-edit-coupom .modal-content {
    width: 950px;
}
@import "../../../../assets/sass/_variables.scss";

.carrinho p {
    margin-bottom: 0px;
}

.dropdown-menu.cart{
    border-radius: 0!important;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    border-top: 0!important;
    border: 0;
    background-clip: inherit;
    z-index: 10000000000;
    margin-top: 0!important;
    right: 0!important;
    left: inherit;
    padding: 0px;
}

.banner-home .dropdown-menu, .container-checkout .dropdown-menu{
    width: 410px;
}

.space-between{
    justify-content: space-between;
}

.titleCart {
    padding: 15px;
    p{
        font-size: 17px;
    }
}

.listItens{
    max-height: 330px;
    overflow: auto;
}

.lineItemCart{
    padding: 20px 15px;
}

.infoCart{
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

.priceControl{
    small{
        font-size: 10px;
        margin-top: 7px;
    }
}

.controlImage{
    // max-width: 125px;
    max-height: 94px;
    min-height: 94px;
    overflow: hidden;
    border-radius: 8px;
}

.controlInfo{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.priceItem{
    font-size: 17px;
    font-weight: bolder;
}

.titleItem{
    font-size: 17px;
    font-weight: bolder;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


.subTotalControl{
    padding: 10px 15px;
    font-weight: 500;
    align-items: center;

    small{
        font-size: 12px;
        font-weight: 500;
        margin-top: 12px;
    }

    .priceSubtotal{
        font-size: 24px;
        font-weight: bolder;
        color: $primaryColor;
    }
}

.bg-cart-bottom {
    background-color: $cart-bottom;
}

.btn-cart{
    padding: 0.6rem 2.5rem;
    font-size: 14px;
    font-weight: 500;
}

.contationControl{
    color: #fff;
    padding: 10px 15px;
    border-top: 1px solid #555064;
    margin-top: 15px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.carrinho .nav-link{
    width: 100%;
    display: flex;
    padding: 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-radius: 4px;
    .title-cart {
        font-size: 18px;
        font-weight: 600;
    }

    .indicate-number {
        width: 20px;
        height: 20px;
        font-size: 14px;
        font-weight: 700;
        background-color: $secondaryColor;
        color: $text-color;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.bg-primary-dark {
    background-color: $secondaryColor;
}

@media (max-width: 767px) {
    .banner-home .dropdown-menu, .container-checkout .dropdown-menu{
        width: 375px;
    }

    .btn-cart{
        padding: 0.6rem 2rem;
    }
}
@import "../../assets/sass/_variables.scss";

.users-table {
    & .table-container {
        & .table {

            & thead tr th:nth-child(1),
            tbody tr td:nth-child(1) {
                width: 7%;
                padding-left: 20px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            & thead tr th:nth-child(2),
            tbody tr td:nth-child(2) {
                width: 8%;
            }

            & thead tr th:nth-child(3),
            tbody tr td:nth-child(3) {
                width: 10%;
            }

            & thead tr th:nth-child(4),
            tbody tr td:nth-child(4) {
                width: 20%;
            }

            & thead tr th:nth-child(5),
            tbody tr td:nth-child(5) {
                width: 15%;
            }

            & thead tr th:nth-child(6),
            tbody tr td:nth-child(6) {
                width: 10%;
            }

            & thead tr th:nth-child(7),
            tbody tr td:nth-child(7) {
                width: 15%;
            }

            & thead tr th:nth-child(8),
            tbody tr td:nth-child(8) {
                width: 10%;
            }

            & thead tr th:nth-child(9),
            tbody tr td:nth-child(9) {
                width: 5%;
                text-align: center !important;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
}

.status-modal{

    .form-control.calendar-input {
        padding: 0.375rem 1.75rem;
        max-height: 31px;
        padding-right: 10px !important;
    }
    .modal-header{
        position: unset;
        border-bottom: unset;
        right: unset;
        padding: 0px;
        z-index: unset;
        width: 100%;
        align-items: flex-start;

        .title-reservation{
            font-weight: 700;
        }
    }

    .col-md-12 {
        padding-right: 10px;
    }

    .modal-content {
        padding: 0 !important;
    }
    .buttons-options {
        display: flex;
    }
    .close{
        background-color: transparent !important;
        border: unset !important;
        font-size: 25px !important;
        width: unset !important;
    }
    
    .modal-dialog.modal-lg{
        max-width: 1024px;
    }

    .modal-content{
        width: 100% !important;
        height: unset;

        .modal-body{
            height: unset;
            overflow: auto;
            justify-content: flex-start !important;
        }
    }

   
    .details-reservations-submit-button{
        background-color: $primaryColor !important;
        color: #fff !important;
        min-height: 38px;
        min-width: 125px;
    }

    span.subtitle-modal {
        font-size: 14px;
        text-transform: uppercase;
        display: flex;
    }

    span.response-modal {
        font-size: 14px;
    }

    .icon-code{
        padding: 12px 4px;
        border-radius: 50%;
        border: 1px solid;
        width: 50px;
        height: 50px;
    }
}

.logo-user{
    border: 2px solid #707070;
    border-radius: 50%;
    width: 120px;
    height: 120px;
}

.logo-user.user-list{
    width: 55px;
    height: 55px;
}
@import '../../../../assets/sass/_variables.scss';

.commissions-report{

    .cards{
        margin: 40px 0;

        .card-unit{
            border-left: 2px solid $primaryColor;
            padding-left: 0;

            .card{
                display: flex;
                flex-direction: row;
                padding: 7px;

                .card-title{
                    color: #A1ADC1;
                    font-weight: 600;
                    font-size: 10px;
                }

                .cart-content{
                    color: #6731FF;
                    font-weight: 600;
                    font-size: 18px;
                }
            }
        }
    }
}

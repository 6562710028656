@import "../../../assets/sass/_variables.scss";

.bg-add-partners {
    background-color: #F2F2F2;
    width: 100%;
    height: 100%;
}

.partners h2 {
    text-align: left;
    font-size: calc(1.2rem + .5vw);
}

.partners-guide{
    max-width: 1000px;
    & .step-labels span:nth-child(1) {
        left: -7px;
    }
    & .step-labels span:nth-child(2) {
        left: 15px;
    }
    & .step-labels span:nth-child(3) {
        left: 0px;
    }
    & .step-labels span:nth-child(4) {
        left: 17px;
    }
}

// STEPS
.steps {
    align-items: center;
}

.steps .step {
    font-weight: 500;
    background-color: #adb5bd;
    width: 30px;
    height: 30px;
    min-width: 30px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    border-radius: 15px;
}

.steps .stepline {
    width: calc(50% - 45px);
    height: 5px;
}

/* .step-labels {
    width: 110%;
    margin-left: -5%;
}
 */
.step-labels span {
    font-size: 16px;
}

.page-partners .rmdp-container{
    width: 100%;
}


.partners-filter .radioPersonal {
    border: 1px solid;
    padding: 0 0 0 30px;
}

.table > :not(:first-child){
    border-top: none;
}

.ranger-calendar .rmdp-container {
    width: 100%;
}

.ranger-calendar .form-control[readonly]{
    background-color: transparent;
}

.sales-report {

    & ul {
        margin-bottom: 0 !important;

        & .nav-link {
            font-weight: 500;
            text-transform: uppercase;
            padding: 16px 48px !important;
        }

        & .nav-link.active {
            color: #707070 !important;
        }
    }

    & .tab-content {
        background-color: #ffff;
        color: #707070;
        padding: 16px 24px;
        text-align: left;
        border: 1px solid;
        border-top: none;
        border-color: #dee2e6;
        margin-bottom: 20px;
        margin-top: 2rem;
    }

    .nav-link {
        background-color: #d8d8d8 !important;
    }

    .nav-link.active {
        border: 1px solid !important;
        border-color: #dee2e6 !important;
        border-bottom: none !important;
        background-color: #ffff !important;
    }

    .btn-outline-info {
        height: 38px;
    }
}

.first-chart {
    margin-bottom: 116px;
}
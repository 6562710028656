@import "../../assets/sass/_variables.scss";

.pt-main-container {
    max-width: 600px;
}

.pt-content {
    border: none;
    padding: 1.5rem 2.5rem;
    //margin-bottom: 2.5rem;

    & .pt-title {
        font-size: 28px;
        font-weight: 700;
        padding-bottom: 24px;
        border-bottom: 1px solid #B5B5B5;
    }

    & .pt-content-info {
        padding: 1.5rem 0 2.5rem;
        border-bottom: 1px solid #B5B5B5;

        & .form-check-label {
            font-size: .875rem;
            font-weight: 400 !important;
            position: relative;
            bottom: 0.1rem;
        }

        & button {
            min-width: 2.25rem;
        }

        & .label-small.btn-disabled {
            background-color: #707070;
            color: #FFF;
        }

        & .btn-edit {
            border: 1px solid #707070;
            border-radius: 4px;
            color: #707070;
            padding: .05rem .75rem;
            font-size: .875rem;
            position: relative;
            bottom: 15px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:hover {
                opacity: .6;
            }
        }


        & .input-select-custom {
            & .form-select {
                border: 1px solid #ced4da !important;
            }
        }

        & .small {
            font-weight: 600;
        }

        & .bg-image-info {
            background-color: #deeaff;
            padding: .5rem .75rem;
            border-radius: .5rem;
        }

        & .bg-images {
            background-size: cover;
            background-position: center center;
            cursor: grab;
            width: 23.65%;
            height: 260px;

            &:first-child {
                width: 100%;
                background-size: 40%;
                background-repeat: no-repeat;
                background-position: left center;
                height: 330px;
            }
        }

        & .btn-image {
            border: 1px solid #707070;

            &:hover {
                opacity: .6;
            }
        }

        & .pt-age-check {

            &.form-switch .form-check-input {
                width: 3rem !important;
                padding: .65rem !important;
            }
        }

        & .pt-google-maps {
            position: relative;
            bottom: 80px;
            height: 390px;
        }

        & .offset-md-6 {
            margin-right: 50%;
            margin-left: 0px;
        }

    }

    & .modalidades-cadastradas {
        padding: 0 0 0 24px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 15px;

        & .modality-name {
            width: 100%;
            max-width: 49%;
            //background-color: rgba(112, 112, 112, .1);
            border: 1px solid #ccc;
            padding: 8px;
            border-radius: 5px;

            * .dropstart{
                
                & button{
                    background-color: #fff;
                    border: none;
                    padding: 0 12px;
                }

                & *::before {
                    display: none;
                }
            }

            &.edit {
                border: 2px solid $primaryColor;
            }
        }
    }
    
    & .tariffs-cadastradas {
        padding: 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        & .tariff-name {
            width: 100%;
            //background-color: rgba(112, 112, 112, .1);
            border: 1px solid #ccc;
            padding: 8px;
            margin-bottom: 8px;
            border-radius: 5px;

            * .dropstart{
                
                & button{
                    background-color: #fff;
                    border: none;
                    padding: 0 12px;
                }

                & *::before {
                    display: none;
                }
            }

            &.edit {
                border: 2px solid $primaryColor;
            }
        }

        .bloqueado{
            background-color: #E27376;
            border-radius: 8px;
            padding: 3px 10px;
            color: #fff;
        }
        
        .ativo{
            background-color: $primaryColor;
            border-radius: 8px;
            padding: 3px 10px;
            color: #fff;

        }
    }

    /* & .integrations {
        padding: 0 12px;

        & .integration-name {
            background-color: rgba(112, 112, 112, .1);
            padding: 8px;
            border-radius: 5px;
            margin: 5px 0;
            cursor: pointer;
        }
    } */

    & .pt-content-supplier {

        & .pt-subtitle {
            font-size: 1.125rem;
            font-weight: 600;
        }

        & .form-check-label {
            font-size: .875rem;
        }
    }

    & .btn-add-details {
        border: 1px solid #707070;
        border-radius: 4px;
        padding: .05rem .75rem;
        font-size: .875rem;
        font-weight: 600;
        cursor: pointer;

        &:hover {
            opacity: .6;
        }
    }

}

.add-product {
    .card {
        border-radius: 0px;

        &.menu {
            padding: 0px !important;
        }
    }

    .label-small {
        font-size: 14px;
        font-weight: 500;
    }

    .radioPersonal {
        box-shadow: 0px 2px 6px 0px #70707025;
    }

    .pac-target-input {
        padding: 0.375rem 0.75rem;
    }
}

.list-menu {
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
}

.sub-list-menu {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.list-menu-item.item-title {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 15px;
    padding: 0px 20px
}

.list-menu-item.item-action {
    font-size: 14px;
    font-weight: 500;
    color: #8E8E8E;
    padding: 0px 30px;

    & .border-bottom {
        border-bottom: 1px solid #EDEDED !important;
    }

    &.active {
        background-color: #ededed;
    }

    p {
        margin-bottom: 0px;
        border-bottom: none !important;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &.success:hover {
        background-color: #f3f3f3;
    }
}

.line-chooice.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.bg-edit-transfer {
    cursor: pointer;

    & p {
        &:hover {
            color: #000000;
        }
    }
}

.content-modality-add {
    padding: 15px;
    border: 1px solid #e3e3e3;
}

.product-integration {
    .modal-content {
        padding: 30px !important;
        height: unset;
    }

    .modal-header {
        position: relative;

        .pt-title {
            width: 100%;
        }

        .close-btn {
            position: absolute;
            right: 0;
        }
    }

    .integration-type {
        display: flex;
        align-items: center;
        margin: 30px 0 15px;

        .outside {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: 1px solid $primaryColor;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;

            .inside {
                width: 70%;
                height: 70%;
                background-color: $primaryColor;
                border-radius: 50%;
            }
        }
    }

}

.endpoint .comission div#react-select-7-listbox * {
    background-color: #fff;
    color: #000000
}

.endpoint .comission div#react-select-7-listbox *:hover {
    background-color: #1E90FF;
    color: #fff
}

.promocoes-cadastradas {
    & .table-container {
        & .table {
            & thead {
                & tr {
                    margin: 0;
                    border: none;
                }
            }

            & thead tr th:nth-child(1),
            tbody tr td:nth-child(1) {
                width: 20%;
                padding-left: 20px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            & thead tr th:nth-child(2),
            tbody tr td:nth-child(2) {
                width: 20%;
            }

            & thead tr th:nth-child(3),
            tbody tr td:nth-child(3) {
                width: 20%;
            }

            & thead tr th:nth-child(4),
            tbody tr td:nth-child(4) {
                width: 20%;
            }

            & thead tr th:nth-child(5),
            tbody tr td:nth-child(5) {
                width: 16%;
            }

            & thead tr th:nth-child(6),
            tbody tr td:nth-child(6) {
                width: 4%;
                text-align: center;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
}
@import "../../../../../../../../assets/sass/_variables.scss";

.passengers-transfers {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1.5rem 0 3.5rem;
    & p {
        margin-bottom: .5rem !important;
    }
    & b {
        padding-right: 25px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.icons-transfer {
    display: flex;
    align-items: center;

    & svg, img {
        cursor: pointer;
        &:hover {
            opacity: .7;
        }
    }
}
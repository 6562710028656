@import "../../../../assets/sass/_variables.scss";

.reservations-table.corporate-client-table {
    & .table-container {
        & .table {

            & thead tr th:nth-child(1),
            tbody tr td:nth-child(1) {
                width: 20%;
                padding-left: 20px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            & thead tr th:nth-child(2),
            tbody tr td:nth-child(2) {
                width: 20%;
            }

            & thead tr th:nth-child(3),
            tbody tr td:nth-child(3) {
                width: 20%;
            }

            & thead tr th:nth-child(4),
            tbody tr td:nth-child(4) {
                width: 4%;
                text-align: center;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }

    & .dropdown-item {

        & svg {
            width: 20px;
        }
    }
}

.btn-export {
    border: 2px solid #ccc !important;
    border-radius: 5px;
    padding: 5px 10px;
}

.btn-export img {
    margin-right: 5px;
}
.content-page-transfers {
    margin: 150px auto 55px;

    & .card-left {
        & h5 {
            font-size: 1rem;
        }
    }

    & .card:last-child {
        margin-bottom: 0 !important;
    }
}

.bg-vehicles {
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 100%;
    width: 100%;
    padding: 20px;
    min-height: 120px;
}

.info-travel {
    width: 95%;

    & p {
        margin-top: 5px;
        line-height: 1.2rem;
        margin-bottom: 0;
        font-size: 15px;
    }
}

.time-list {
    font-weight: 700;
}

.list-price {
    text-align: center;
    width: 90%;

    & p {
        margin-bottom: 0;
    }

    & h3 {
        font-size: 42px !important;
        font-weight: 600;
    }

    & button {
        border-radius: 0;
        width: 100%;
    }
}

.card-price {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E5E5E5;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}


@media (max-width: 767px) {
    .content-transfers {
        margin: 200px auto 55px;
    }

    .bg-vehicles {
        background-position: bottom center !important;
        background-size: cover !important;
        height: 190%;
        width: 90%;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        margin: auto;
    }

    .text-transfer {
        margin-top: 5.5rem;
    }

    .info-travel {
        width: unset;
    }

    .card-price {
        padding: 20px 0;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 5px;
    }

    .bg-img-transfer {
        & .badge {
            margin: 1rem !important;
        }
    }
}

@media (min-width: 415px) and (max-width: 767px) {
    .bg-vehicles {
        background-position: bottom center !important;
        background-size: contain !important;
        height: 175%;
    }
}

@media (max-width: 991px) {
    .filter-modal {
        left: 5% !important;
        width: 90% !important;
        height: 680px !important;
    }

    #filter-transfer {
        left: -375px;
        height: 680px !important;
        transition: all .3s;
        width: unset;
        position: absolute;
        z-index: 100;
    }

    #shadow {
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .3);
        z-index: 99;
        display: none;
    }

    button.x-close-button {
        background: transparent;
        right: 0;
        top: 0;
        position: absolute;
        margin: 0.5rem;
        border: 0;
    }

    .hidden {
        overflow: hidden;
    }
}

@media (min-width: 700px) and (max-width: 991px) {
    .filter-modal {
        left: 20% !important;
        width: 480px !important;
        height: 680px !important;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .list-price h3 {
        font-size: 32px !important;
    }
}

.navTabHome {
    & .content-page-transfers {
        color: #6C6C6C !important;
        margin: 40px auto 55px !important;
    }
}
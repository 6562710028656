.associate-category {
    .card-body {
        padding: 3rem;
    }

    .head {
        gap: 50px;

        button {
            background-color: transparent !important;
            border: 1px solid #707070;
            border-radius: 8px;
            padding: 0 30px;
            font-size: 16px;
            max-height: 28px;
        }
    }

    & .react-bootstrap-table tbody tr {
        margin: 20px 0;
        border-color: #cecece;
        border-right: 1px solid #cecece;
        border-top: 1px solid #cecece;
        border-bottom: 1px solid #cecece;
        border-left: 1px solid #cecece;
        transition: .3s;


        &:hover {
            border-color: #cecece;
        }
    }

    .table-default.father {
        margin-top: 0;

        .table-container {
            box-shadow: none;
            border: none;
            padding-left: 0;
            padding-right: 0;

            & thead tr th:nth-child(1),
            tbody tr td:nth-child(1) {
                width: 33%;
                padding-left: 20px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }


            & thead tr th:nth-child(2),
            tbody tr td:nth-child(2) {
                width: 33%;
                text-align: left;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            & thead tr th:nth-child(3),
            tbody tr td:nth-child(3) {
                width: 34%;
                padding-left: 20px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
        }
    }


}

.ModalAssociateCategorieFound-table, .ModalAssociateAffiliatesFound-table {
    margin-top: 0;

    .table-container {
        box-shadow: none;
        border: none;
        padding-left: 0;
        padding-right: 0;

        & thead tr th:nth-child(1),
        tbody tr td:nth-child(1) {
            width: 5%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }


        & thead tr th:nth-child(2),
        tbody tr td:nth-child(2) {
            width: 50%;
            padding-left: 20px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        & thead tr th:nth-child(3),
        tbody tr td:nth-child(3) {
            width: 25%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        & thead tr th:nth-child(4),
        tbody tr td:nth-child(4) {
            width: 20%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }
}
input.input-canal {
    padding-left: 150px;
    max-width: 250px;
}

label.nome-canal.form-label {
    position: absolute;
    margin: 0px;
    padding: 6.5px;
    /* min- */width: 125px;
    text-align: center;
    /* margin-left: 0.5rem; */
    border-right: 1px solid #c2c2c2;
}

.title_stock{
    height: 2rem;
    padding: 1rem 0rem;
    margin: 0;
}

.sub_title_stock {
    font-size: 18px;
    padding-bottom: 2rem;
    color: #00632D;
}

// FONT-SIZES

.font-sub-title{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
}

.bg-stock-control {
    padding-top: 2rem;
    border-bottom: 1px solid #7070703d;
}

.bg-stock-control:first-child {
    padding-top: 0 !important;
}

.bg-stock-control:last-child {
    border-bottom: none !important;
}


@import "../../../../assets/sass/_variables.scss";

.blackout-table {
    & .table-container {
        background-color: white;
        padding: 20px 25px;

        & .table {

            & thead tr th:nth-child(1),
            tbody tr td:nth-child(1) {
                width: 25%;
                padding-left: 20px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            & thead tr th:nth-child(2),
            tbody tr td:nth-child(2) {
                text-align: left;
                width: 50%;
            }
            
            & thead tr th:nth-child(3),
            tbody tr td:nth-child(3) {
                text-align: left;
                width: 15%;
            }

            /* & thead tr th:nth-child(4),
            tbody tr td:nth-child(4) {
                width: 15%;
            } */

            & thead tr th:nth-child(4),
            tbody tr td:nth-child(4) {
                width: 5%;
                text-align: center;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }

    .bg-reservation-ativo {
        background-color: #7DD4AB;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 10px;
        text-align: center;
        width: 100%;
        max-width: unset;
        font-size: 14px;
        line-height: unset;
    }

    /* .bg-reservation-analise{
        background-color: #6bc8dd ;
        color: #FFF ;
        border-radius: 10px ;
        padding: 5px 10px ;
        text-align: center ;
        width: 100% ;
        max-width: unset ;
        font-size: 14px ;
        line-height: unset ;
    } */

    .bg-reservation-inativo {
        background-color: #E27376;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 10px;
        text-align: center;
        width: 100%;
        max-width: unset;
        font-size: 14px;
        line-height: unset;
    }

    .bg-reservation-preReserva {
        background-color: #6bc8dd;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 10px;
        text-align: center;
    }

    .bg-reservation-naoAprovado {
        background-color: #ffb001;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 10px;
        text-align: center;
    }
}

.blackout-suppliers-table {
        background-color: white;
        padding: 0px 25px;
        max-height: 405px;
        overflow-y: scroll;
        margin: 0;

        & .table {
            & tr {
                min-height: unset;
                height: 40px !important;
                margin: 10px 0 !important;
            }

            & thead tr th:nth-child(1),
            tbody tr td:nth-child(1) {
                width: 5%;
                padding-left: 20px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            & thead tr th:nth-child(2),
            tbody tr td:nth-child(2) {
                width: 75%;
            }
           /*  & thead tr th:nth-child(2),
            tbody tr td:nth-child(2) {
                width: 20%;
            } */

        }
    
}

.blackout-load {
    width: 18px;
    height: 18px;
    margin: 0 auto;
    border: solid 3px #6e707e;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-name: rotate;
    animation-duration: 1.0s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
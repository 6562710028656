.text-forgot-pass {
    color: #858796;
}

.title-forgot-pass {
    color: #666;
}

.link-forgot-pass {
    margin: 20px 0 50px;
    & a {
        text-decoration: none;
        &:hover {
            color: #224abe;
            text-decoration: underline;
        }
    }
}

.valign-forgot-pass p {
    margin-bottom: 100px !important;
}

.btn-forgot {
    margin: 40px 0;
}
.react-modal-control-quill {    
    box-sizing: border-box;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    box-shadow: 0px 4px 2.5px 0px #70707025;

    .quill {
        max-width: 100%;
    }

    .label{
        padding-left: 15px;
        font-weight : 400;
        border-bottom: 1px solid #ced4da !important;
        width: 100%;
    }

    .ql-snow {    
        border: none !important;  
        font: 16px 500 var(--bs-body-font-family) #6C6C6C !important; 
        
    } 
    
    .ql-editor{    
        height: 126px !important;
        padding: 0 15px 10px ;      
    }
}
@import "../../../../../assets/sass/_variables.scss";

.edit-suppliers-data .observation{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
}

.edit-suppliers-data .observation-content{
    width: 100%;
    background-color: white;
    border-left: 10px solid $primaryColor;
    text-align: left;
}

.edit-suppliers-data .observation-content p{
    font-size: 12px;
    margin-bottom: 9px;
    margin-left: 9px;
    margin-top: 6px;
}

.edit-suppliers-data .edit{
    width: 100%;
}

.edit-suppliers-data .steps{
    width: 11%;
    margin-top: 40px;
}

.edit-suppliers-data .steps-sticky{
    position: sticky;
    top: 20px;
    font-size: 12px;
    line-height: 24px;
    margin-right: 6px;
}

.edit-suppliers-data .step-unit{
    margin-bottom: 15px;
}

.edit-suppliers-data .step-unit a{
    display: flex;
    text-decoration: none;
}

.edit-suppliers-data .step-unit.active span{
    color: white;
    width: 25px;
    height: 25px;
    background-color: $primaryColor;
    border-radius: 50%;
    margin-right: 5px;
    text-align: center;
}

.edit-suppliers-data .step-unit.active p{
    color: $primaryColor;
    font-weight: bold;
}

.edit-suppliers-data .step-unit span{
    color: white;
    width: 25px;
    height: 25px;
    background-color: #707070;
    border-radius: 50%;
    margin-right: 5px;
    text-align: center;
}

.edit-suppliers-data .step-unit p{
    color: #707070;
    font-weight: bold;
}

.edit-suppliers-data .border{
    box-shadow: rgba(0, 0, 0, 0.20) 0px 3px 5px;
    margin-bottom: 75px;
    width: 100%;
}

.edit-suppliers-data .edit-title{
    display: flex;
    justify-content: space-between;
    background-color: white;
}

.edit-suppliers-data .edit-title h3{
    text-align: left;
    font-size: 18px;
    /* margin: 10px 0 20px 15px; */
    color: $primaryColor;
}

.edit-suppliers-data .edit-title button{
    width: 120px;
    height: 38px;
    background-color: transparent;
    border: 1px solid $primaryColor;
    border-radius: 6px;
    opacity: 1;
    color: $primaryColor;
    transition: background-color .3s;
}
.edit-suppliers-data .edit-title button:hover{
    color: #fff;
    background-color: $primaryColor;;
}

.edit-suppliers-data .data, .edit-page .data{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 1.5rem;
    background-color: white;
    
}
.edit-suppliers-data .data-unit, .edit-page .data-unit{
    max-width: 32%;
    width: 100%;
    height: 72px;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 0 1.5rem;
    margin: 10px 0;
    & p:nth-child(1) {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
    }
}
.edit-suppliers-data .data-unit.blank, .edit-page .data-unit.blank{
    background-color: #fff;
}


.edit-suppliers-data .data-unit.col-20, .edit-page .data-unit.col-20{
    max-width: 20%;
}
.edit-suppliers-data .data-unit.col-10, .edit-page .data-unit.col-10{
    max-width: 10%;
}
.edit-suppliers-data .data-unit.col-50, .edit-page .data-unit.col-50{
    max-width: 45%;
}
.edit-suppliers-data .data-unit.col-100, .edit-page .data-unit.col-100{
    max-width: 100%;
}
.data-unit-white-bg{
    background-color: white !important;
}

.edit-suppliers-data p, .edit-page .data p{
    margin: 0;
}



.description-sortable-knob{
    cursor: grab;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.description-sortable-knob:active{
    cursor: grabbing;
}
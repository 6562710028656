@import "../../../assets/sass/_variables.scss";

.menu-level-2{
    position: absolute;
    top: 0;
    left: 18.5rem;
    background-color: #E9EAEE;
    height: 100vh;

}

.menu-level-2{
    border-left: 2px solid $primaryColor;
}
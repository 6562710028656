@import "../../../assets//sass/_variables.scss";

.invalid-payments{
    display: none;
}

.bg-previous {
    background: #94969e !important;
    color: #fafafa;
    border: 0;
}

button#card-button {
    max-height: 56px;
}

.load.medium {
    width: 25px;
    height: 25px;
    border-color: #fff !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
}

.modalCheckout .modal-dialog {
    width: 520px !important;
    height: 650px;
}

// .modalCheckout .modal-content {
//     max-height: 600px;
//     width: 520px !important;
//     height: 650px;
//     padding: 20px;
// }

.card.method {
    min-height: auto !important;
}

.modalCheckout .modal-content .modal-body {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: initial;
}

.modal-btn-controller {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalCheckout .modal-content .fa-5x {
    font-size: 7.5em;
}

ul.optionsPayment li {
    max-width: 250px;
    width: 250px;
}

ul.optionsPayment {
    display: flex;
}

.custom-control-payment{
    padding: 10px 15px;
    border: 1px solid #999999;
    border-radius: 10px;
    cursor: pointer;
}

input#paymentPix, input#paymentCreditCard {
    display: none;
}

@media(max-width: 480px) {
    .modalCheckout .modal-dialog {
        width: 350px !important;
        height: 500px;
        margin: 0 auto;
        margin-top: 35px;
    }
    
    .modalCheckout .modal-content {
        max-height: 600px;
        width: 350px !important;
        height: auto;
    }
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
}
 

 @-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to { 
        -webkit-transform: rotate(360deg);
    }
}

.modalPayment{

    .modal-dialog.modal-lg{
        height: unset;

        .modal-content{
            height: unset;
            max-height: unset;
            padding: 20px 0;

            .sucess-pos{
                height: 540px;
                max-height: 540px;
                overflow-y: hidden;
                display: flex;
                flex-direction: column;
                align-content: center;
                justify-content: center;
            }
        }
    }

}

.pix-container {
    &.bg-link-external {
        & p {
            font-size: 1rem !important;
        }

        & h4 {
            font-size: 1.25rem !important;
        }

        & button {
            font-size: 1rem !important;
        }

        & img {
            width: 50%;
        }

        & a.whatsapp-link {
            text-decoration: none !important;
            margin-top: .875rem;

            & button {
                border-color: #25d366 !important;
                color: #25d366 !important;

                &:hover {
                    background-color: #25d366 !important;
                    color: #FFFFFF !important;
                }
            }
        }
    }
    & .modal-btn-controller {
        & button {
            border-color: var(--primary) !important;
            color: var(--primary) !important;
        }
    }
}
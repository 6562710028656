@import "../../../../assets/sass/_variables.scss";

.settle-receive {
    & .table-container {
        & .table {
            & thead tr th:nth-child(1),
            tbody tr td:nth-child(1) {
                width: 4%;
                padding-left: 20px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            & thead tr th:nth-child(2),
            tbody tr td:nth-child(2) {
                width: 8%;
            }

            & thead tr th:nth-child(3),
            tbody tr td:nth-child(3) {
                width: 8%;
            }

            & thead tr th:nth-child(4),
            tbody tr td:nth-child(4) {
                width: 8%;
            }

            & thead tr th:nth-child(5),
            tbody tr td:nth-child(5) {
                width: 12%;
            }

            & thead tr th:nth-child(6),
            tbody tr td:nth-child(6) {
                width: 8%;
            }

            & thead tr th:nth-child(7),
            tbody tr td:nth-child(7) {
                width: 8%;
            }

            & thead tr th:nth-child(8),
            tbody tr td:nth-child(8) {
                width: 12%;
            }

            & thead tr th:nth-child(9),
            tbody tr td:nth-child(9) {
                width: 10%;
            }

            & thead tr th:nth-child(10),
            tbody tr td:nth-child(10) {
                width: 6%;
            }

            & thead tr th:nth-child(11),
            tbody tr td:nth-child(11) {
                width: 8%;
            }

            & thead tr th:nth-child(12),
            tbody tr td:nth-child(12) {
                width: 8%;
                text-align: center;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
}
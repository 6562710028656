.modal-content-note {
    & .payments-note {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-left: 5px solid #707070;
        padding-left: 1rem;
        margin-bottom: 2.5rem;
        height: 90px;

        & .date-payments-note {
            padding-top: 5px;
            font-weight: 700;
            color: #707070;
        }

        & .content-payments-note {
            display: flex;

            & p {
                font-size: .875rem;
                margin-bottom: .25rem;
            }

            & b {
                font-size: .8rem;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
@import "./_variables.scss";

.table-default {
    display: flex;
    justify-content: center;
    width: 100% !important;
    margin: 1.5rem 0;

    &.loading {
        & tbody tr {
            border: 1px solid rgb(206, 206, 206) !important;
        }

        & .row-loading {
            height: 100%;
            border-radius: 5px;
        }
    }

    & .table-container {
        width: 100%;
        //box-shadow: 0px 3px 6px #00000029 !important;
        border: 1px solid #7070704A;
        background-color: white;
        padding: 20px 25px;

        & tr:nth-child(2n+1) {
            background-color: #F2F2F2 !important;
        }

        & table {
            margin: 0 auto 0 auto
        }
    }
}

.btn-bills {
    border: 1px solid #cecece !important;
    color: #6e707e;
}

.table-default .table-title h3 {
    text-align: left;
    font-size: 20px;
    color: $primaryColor;
}

.table-default table {
    max-width: 100%;
}

.table-default tr th {
    background-color: $primaryColor;
    color: white;
    font-weight: 600;
}

.table-title {
    padding: 0 !important;
}

.table-default .dropstart .dropdown-toggle::before {
    display: none;
}

.table-default button {
    background-color: transparent;
    border: none;
}

.table-default #pageDropDown {
    background-color: $primaryColor;
    color: white;
    margin-left: 0 !important;
    max-width: 70px;
}

.table-default .pagination.react-bootstrap-table-page-btns-ul {
    margin-right: 0 !important;
}

.table-default .page-item .page-link {
    color: $primaryColor;
}

.table-default .page-item.active .page-link {
    background-color: $primaryColor;
    border-color: $primaryColor;
    color: white;
}

.table-default.not-found th,
.table-default.not-found td {
    text-align: center;
}

.table-default {
    & tr {
        height: 64px;
    }

    & .bg-analise {
        background-color: $primaryColor;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 0;
        text-align: center;
        width: 100px;
        max-width: 100px;
        font-size: 12px;
        line-height: 12px;
    }

    & .bg-notApproved {
        background-color: #acacac;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 0;
        text-align: center;
        width: 100px;
        max-width: 100px;
        font-size: 12px;
        line-height: 16px;
    }

    & .bg-ativo {
        background-color: #7DD4AB;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 0;
        text-align: center;
        width: 100px;
        max-width: 100px;
        font-size: 12px;
        line-height: 12px;
    }

    & .bg-inativo {
        background-color: #E27376;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 0;
        text-align: center;
        width: 100px;
        max-width: 100px;
        font-size: 12px;
        line-height: 12px;
    }

    & .bg-blue {
        color: #fff;
        background-color: #2642f4;
        border-radius: 10px;
        padding: 5px 0;
        text-align: center;
        width: 100px;
        max-width: 100px;
        font-size: 12px;
        line-height: 12px;
    }

    & .bg-lightblue {
        color: #fff;
        background-color: #4960f5;
        border-radius: 10px;
        padding: 5px 0;
        text-align: center;
        width: 100px;
        max-width: 100px;
        font-size: 12px;
        line-height: 12px;
    }

    & .bg-yellow {
        color: #fff;
        background-color: #f6c23e;
        border-radius: 10px;
        padding: 5px 0;
        text-align: center;
        width: 100px;
        max-width: 100px;
        font-size: 12px;
        line-height: 12px;
    }

    & .bg-parcial {
        background-color: $primaryColor;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 10px;
        text-align: center;
    }

    & .bg-emitido {
        background-color: #7DD4AB;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 10px;
        text-align: center;
        font-size: 12px;
        line-height: 12px;
    }

    & .bg-pendente {
        background-color: #E27376;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 10px;
        text-align: center;
        font-size: 12px;
        line-height: 12px;
    }

    & .bg-pendente-escala {
        background-color: #ffd24b;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 10px;
        text-align: center;
        font-size: 12px;
        line-height: 12px;
    }

    & .fg-ativo {
        color: #7DD4AB;
    }

    & .fg-inativo {
        color: #E27376;
    }

    & .fg-blue {
        color: #2642f4;
    }

    & .fg-lightblue {
        color: #4960f5;
    }

    & .fg-yellow {
        color: #f6c23e;
    }

    & .fg-gray {
        color: #808080;
    }

    & .react-bootstrap-table {
        & table {
            border-collapse: inherit;
            border-spacing: 0;
            padding: 0 10px;
        }

        .table> :not(caption)>*>* {
            max-height: 60px;
        }
    }

    & thead,
    tfoot {
        & tr {
            border-top: none;

            & th {
                background-color: #FFf;
                color: $primaryColor;
            }
        }
    }

    & tfoot {
        border-bottom: 1px solid #FFF;
    }

    & tr {
        vertical-align: middle;
    }

    & .react-bootstrap-table {
        margin: 1rem 0 1.5rem;

        & table {
            border-collapse: inherit;
            border-spacing: 0;
            padding: 0 !important;
        }

        & thead {
            & tr {
                margin: 0;
                border: none;

                & th {
                    background-color: #FFF;
                    color: $primaryColor;
                }
            }
        }

        & tr {
            margin: 20px 0;
            border-left: 2px solid $primaryColor;
            display: table;
            width: 100%;
            border-width: 0;
            border: 1px solid rgb(206, 206, 206);
            border-radius: 5px;
            vertical-align: middle;
            min-height: 72px;

            & span {
                font-weight: 400;
            }
        }

        & td {
            font-weight: 500;
            color: #707070 !important;
        }

        & tbody {
            & tr {
                border-color: $primaryColor;
                border-right: 1px solid rgb(206, 206, 206);
                border-top: 1px solid rgb(206, 206, 206);
                border-bottom: 1px solid rgb(206, 206, 206);
                border-left: 2px solid $primaryColor;
                transition: .3s;

                &:hover {
                    border-right: 1px solid $primaryColor;
                    border-top: 1px solid $primaryColor;
                    border-bottom: 1px solid $primaryColor;
                    border-color: $primaryColor;
                }
            }
        }

        .border-analise {
            border-color: $primaryColor;
            border-right: 1px solid rgb(206, 206, 206);
            border-top: 1px solid rgb(206, 206, 206);
            border-bottom: 1px solid rgb(206, 206, 206);
            border-left: 2px solid $primaryColor;
            transition: .3s;

            &:hover {
                border-right: 1px solid $primaryColor;
                border-top: 1px solid $primaryColor;
                border-bottom: 1px solid $primaryColor;
                border-color: $primaryColor;
            }
        }

        .border-ativo {
            border-color: #7DD4AB;
            border-right: 1px solid rgb(206, 206, 206);
            border-top: 1px solid rgb(206, 206, 206);
            border-bottom: 1px solid rgb(206, 206, 206);
            border-left: 2px solid #7DD4AB;
            transition: .3s;

            &:hover {
                border-right: 1px solid #7DD4AB;
                border-top: 1px solid #7DD4AB;
                border-bottom: 1px solid #7DD4AB;
                border-color: #7DD4AB;
            }
        }

        .border-pendente-escala {
            border-color: #FFD24B;
            border-right: 1px solid rgb(206, 206, 206);
            border-top: 1px solid rgb(206, 206, 206);
            border-bottom: 1px solid rgb(206, 206, 206);
            border-left: 2px solid #FFD24B;
            transition: .3s;

            &:hover {
                border-right: 1px solid #FFD24B;
                border-top: 1px solid #FFD24B;
                border-bottom: 1px solid #FFD24B;
                border-color: #FFD24B;
            }
        }

        .border-inativo {
            border-color: #E27376;
            border-right: 1px solid rgb(206, 206, 206);
            border-top: 1px solid rgb(206, 206, 206);
            border-bottom: 1px solid rgb(206, 206, 206);
            border-left: 2px solid #E27376;
            transition: .3s;

            &:hover {
                border-right: 1px solid #E27376;
                border-top: 1px solid #E27376;
                border-bottom: 1px solid #E27376;
                border-color: #E27376;
            }
        }

        .border-normal {
            border-color: rgb(206, 206, 206);
            border-right: 1px solid rgb(206, 206, 206);
            border-top: 1px solid rgb(206, 206, 206);
            border-bottom: 1px solid rgb(206, 206, 206);
            border-left: 1px solid rgb(206, 206, 206);
            transition: .3s;

            &:hover {
                border-right: 1px solid rgb(206, 206, 206);
                border-top: 1px solid rgb(206, 206, 206);
                border-bottom: 1px solid rgb(206, 206, 206);
                border-color: rgb(206, 206, 206);
            }
        }

        .table-striped>tbody>tr:nth-of-type(odd)>* {
            --bs-table-accent-bg: #eeeeee;
            color: var(--bs-table-striped-color);
        }

        & .select-pagination {
            & select {
                margin: 0 !important;
            }
        }
    }
}

.react-bootstrap-table-pagination {
    padding-bottom: 0 !important;
    margin: 0;

    & div {
        padding: 0;
    }
}

.table-default .dropdown-item {
    &:hover {
        & a {
            color: #FFF;
        }
    }
}

.table-default.loading {
    td {
        padding: 0 !important;
    }
}

.react-bootstrap-table thead tr th,
.react-bootstrap-table tbody tr td {
    padding: 5px 10px;
    font-size: 14px;
}

.point {
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    width: 10px;

    &.red {
        background-color: #E27376;
    }

    &.gray {
        background-color: #70707075;
    }

    &.green {
        background-color: #7DD4AB;
    }
}

// TABLE

.center-table {
    display: contents;
    text-align: center;
    vertical-align: middle;
}

.table-bordered> :not(caption)>*>* {
    border-width: 0px;
}

.dropdown-item {
    color: #6F6F6F;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #f2f2f2;
    background-color: $primaryColor;
}

.remove.dropdown-item:hover,
.remove.dropdown-item:focus {
    background-color: #e9eaee;
}

// Table AddSuppliers and AddPartners

.btn-contact {
    border: 1px solid #707070;

    &:hover {
        border: 1px solid #707070;
        opacity: .7;
    }
}

.add-contact {
    border: none !important;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.table-add-default {
    width: 100%;

    & .table-container {
        width: 100%;
        border: 1px solid #ccc;
        background-color: white;
    }
}

.table-add-default .table-title h3 {
    text-align: left;
    font-size: 20px;
    color: $primaryColor;
}

.table-add-default table {
    margin: 0 auto 15px auto;
    max-width: 100%;
}

.table-add-default tr th {
    background-color: $primaryColor;
    color: white;
    font-weight: 600;
}

.table-title {
    padding: 1rem 1.25rem;
}

.table-add-default .dropstart .dropdown-toggle::before {
    display: none;
}

.table-add-default button {
    background-color: transparent;
    border: none;
}

.table-add-default #pageDropDown {
    background-color: $primaryColor;
    color: white;
    margin-left: 2%;
    max-width: 70px;
}

.table-add-default .pagination.react-bootstrap-table-page-btns-ul {
    margin-right: 2%;
}

.table-add-default .page-item .page-link {
    color: $primaryColor;
}

.table-add-default .page-item.active .page-link {
    background-color: $primaryColor;
    border-color: $primaryColor;
    color: white;
}

.table-add-default.not-found th,
.table-add-default.not-found td {
    text-align: center;
}

.table-add-default {
    & .bg-analise {
        background-color: $primaryColor;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 0;
        text-align: center;
        font-size: 12px;
        line-height: 12px;
    }

    & .bg-ativo {
        background-color: #7DD4AB;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 20px;
        text-align: center;
        font-size: 12px;
        line-height: 12px;
    }

    & .bg-inativo {
        background-color: #E27376;
        color: #FFF;
        border-radius: 10px;
        padding: 5px 20px;
        text-align: center;
        font-size: 12px;
        line-height: 12px;
    }

    & .table-container {
        & table {
            border-collapse: inherit;
            border-spacing: 0;
            padding: 0 10px;
        }

        & thead {
            & tr {
                margin: 0;
                border: none;

                & th {
                    background-color: #FFF;
                    color: $primaryColor;
                }
            }
        }

        & tbody {
            & tr {
                border-color: $primaryColor;
                border-right: 1px solid rgb(206, 206, 206);
                border-top: 1px solid rgb(206, 206, 206);
                border-bottom: 1px solid rgb(206, 206, 206);
                border-left: 2px solid $primaryColor;
                transition: .3s;

                &:hover {
                    border-right: 1px solid $primaryColor;
                    border-top: 1px solid $primaryColor;
                    border-bottom: 1px solid $primaryColor;
                    border-color: $primaryColor;
                }
            }
        }

        & tr {
            margin: 20px 0;
            display: table;
            width: 100%;
            border-width: 0;
            border: 1px solid rgb(206, 206, 206);
            border-radius: 5px;
            vertical-align: middle;
            min-height: 70px;
        }

        .border-default {
            border-color: $primaryColor;
            border-right: 1px solid rgb(206, 206, 206);
            border-top: 1px solid rgb(206, 206, 206);
            border-bottom: 1px solid rgb(206, 206, 206);
            border-left: 2px solid $primaryColor;
            transition: .3s;

            &:hover {
                border-right: 1px solid $primaryColor;
                border-top: 1px solid $primaryColor;
                border-bottom: 1px solid $primaryColor;
                border-color: $primaryColor;
            }
        }


        .table-striped>tbody>tr:nth-of-type(odd)>* {
            --bs-table-accent-bg: #eeeeee;
            color: var(--bs-table-striped-color);
        }
    }
}

.btn-add {
    background-color: #E9E9E9;
    border: 1px solid #707070;
    border-radius: 4px;
    margin: auto;
    width: 40px;
}

.bg-analise {
    background-color: $primaryColor;
    color: #FFF;
    border-radius: 10px;
    padding: 5px 0;
    text-align: center;
    width: 100px;
    max-width: 100px;
}

.bg-ativo {
    background-color: #7DD4AB;
    color: #FFF;
    border-radius: 10px;
    padding: 5px 0;
    text-align: center;
    width: 100px;
    max-width: 100px;
}

.bg-inativo {
    background-color: #E27376;
    color: #FFF;
    border-radius: 10px;
    padding: 5px 0;
    text-align: center;
    width: 100px;
    max-width: 100px;
}

.bg-blue {
    color: #fff;
    background-color: #2642f4;
    border-radius: 10px;
    padding: 5px 0;
    text-align: center;
    width: 100px;
    max-width: 100px;
}

.bg-lightblue {
    color: #fff;
    background-color: #4960f5;
    border-radius: 10px;
    padding: 5px 0;
    text-align: center;
    width: 100px;
    max-width: 100px;
}

.bg-yellow {
    color: #fff;
    background-color: #f6c23e;
    border-radius: 10px;
    padding: 5px 0;
    text-align: center;
    width: 100px;
    max-width: 100px;
}

.bg-parcial {
    background-color: $primaryColor;
    color: #FFF;
    border-radius: 10px;
    padding: 5px 10px;
    text-align: center;
}

.bg-emitido {
    background-color: #7DD4AB;
    color: #FFF;
    border-radius: 10px;
    padding: 5px 10px;
    text-align: center;
}

.bg-pendente {
    background-color: #E27376;
    color: #FFF;
    border-radius: 10px;
    padding: 5px 10px;
    text-align: center;
}

.bg-pendente-escala {
    background-color: #ffd24b;
    color: #FFF;
    border-radius: 10px;
    padding: 5px 10px;
    text-align: center;
}
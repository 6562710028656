@import "../../../../assets/sass/_variables.scss";

.tour-name {
    color: #666;
}

.tour-list {
    margin-top: 120px;
}

.text-custom {
    min-height: 270px;
}


.text-tour-info {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.price {
    & h5 {
        color: $primaryColor;
        font-size: 1.75rem;
        font-weight: 700;
    }
    & span.h4 {
        font-size: 2rem !important;
        color: #666 !important;
    } 
}

@media (max-width: 1360px) {
    .price {
        & h5 {
            font-size: 1.5rem;
        }
    }
}

.card.card-filter-list {
    max-height: 320px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    //overflow: hidden;
}

.card-filter {
    height: 100%;
    background-color: unset;
    border: none;
}

.card-filter-list {
    border-left: 4px solid #ccc;
    display: inline-table;
    &:hover {
        border-left: 4px solid $primaryColor;
    }
}

.card-img-custom {
    border-radius: 0;
}

.img-mobile {
    overflow: hidden;
}

.text-tour-info {
    font-size: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.small-custom {
    font-size: 90%;
    font-weight: 400;
    margin-bottom: 0.5rem;
    line-height: 1.5rem;

}

@media (max-width: 1366px) {
    .small-custom {
        font-size: 80%;   
    }

}

.btn-options {
    background-color: $primaryColor;
    color: #FFF;
    margin: 10px 0;
    width: 100%;
    &:hover {
        background-color: $primaryColor;
        color: #FFF;
        font-weight: 400;
        opacity: .9;
    }
}

.text-custom {
    & .small.text-muted {
        color: #858796 !important;
    }
    & .text-tour-info {
        font-size: .9rem;
        color: #858796 !important;
        font-weight: 400;
        margin-bottom: 40px;
    }
}

@media (max-width: 1366px) {
    .text-custom {
        & .text-tour-info {
            margin-bottom: 25px;
        }
    }
}

.dates {
    margin-bottom: 15px;
    & span {
        margin: 0 5px;
        font-size: 80%;
        font-weight: 600;
        padding: 0.05rem .20rem .2rem;
        color: #FFF;
        background-color: $primaryColor;
    }
    & span:nth-child(1) {
        margin-left: 0;
    }
}

.btn-details button {
    border: 1px solid $primaryColor;
    border-radius: 0.35rem;
    color: $primaryColor !important;
    font-size: 1rem;
    font-weight: 400;
    padding: 5px 20px;
    margin-bottom: 0;
    &:hover {
        color: #FFF !important;
        background-color: $primaryColor;
    }
}

.paginate-btn{
    font-weight: 600;
    background-color: transparent;
    color: $primaryColor;
    border: none;
    margin: 0 10px;
}

@media (max-width: 991.98px) {
    .tour-name {
        margin-bottom: 1rem;
        font-size: calc(0.8rem + 0.5vw);
    }
    
    .small {
        font-size: calc(0.6rem + 0.5vw);
    }
}

@media (max-width: 575px) {
    .container {
        padding-right: var(--bs-gutter-x, 0.25rem);
        padding-left: var(--bs-gutter-x, 0.25rem);
    }

    .img-mobile {
        padding-right: 0;
        & img {
            height: 135px;
            width: auto;
        }
    }
}

.adult-price {
    margin-top: 4.5rem;
}

.no-content{
    color: $primaryColor;
    text-align: center;
    margin: 15px;
}

.bg-tour-list {
    height: 100%;
    background-size: cover;
    background-position: top left;
}

@media(max-width: 1366px) {
    .bg-tour-list {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top left;
        height: 85%;
    }
}

.stars-tour-list {
    & .svg-inline--fa .fa-secondary {
        fill: #707070 !important;
        opacity: 1;
    }
}

.prod-options {
    & .accordion-item {
        border: none;
        border-radius: 0;
    }

    & .btn-accordion-options {
        position: absolute;
        right: 20px;
        top: 170px;

        & .accordion-button {
            background-color: $primaryColor;
            color: #FFF;
            padding: 10px 50px;
            height: auto;

            &:hover {
                opacity: .9;
            }
        }

        & button:after {
            content: none;
        }
    }

    & .accordion-header:nth-child(1) {
        & .accordion-button {
            background-color: #F2F2F2;
            color: $primaryColor !important;
            font-weight: 500;
            height: 52px;
            border: none;

            &:hover {
                background-color: #CCC;
            }
        }

        & .accordion-button.collapsed {
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 0;

            &:after {
                transform: rotate(-90deg);
                filter: invert(16%) sepia(45%) saturate(1657%) hue-rotate(190deg) brightness(98%) contrast(96%);
                font-weight: 500;
            }
        }

        & .accordion-button:not(.collapsed)::after {
            transform: rotate(0deg);
        }
    }

    & .accordion-body {
        border-bottom: 1px solid #f2f2f2;
    }

    & .accordion-item {
        border-bottom-right-radius: 10px;
    }
}

.sub-info {
    & span {
        margin: 5px 10px;
        color: #858796;
        font-weight: 400;
        font-size: 90%;

        &:nth-child(1) {
            margin-left: 0;
        }
    }
}

.session-not-yes {
    & svg {
        margin-right: 5px;
    }

    & p {
        margin-bottom: 10px;
        color: #858796;
        font-weight: 400;
        font-size: 90%;
    }
}

.yes {
    & svg {
        color: #67C1A8;
    }
}

.not {
    & svg {
        color: #D25A34;
    }
}

.price-add span.h4 {
    font-size: 1.25rem !important;
}

.pr-3 {
    padding-right: 1rem;
}

.pl-3 {
    padding-left: 1rem;
}

.bg-accordion-options {
    padding: 5px 10px;
}

@media (max-width: 1600px) {
    .prod-options {
        & .btn-accordion-options {
            position: absolute;
            right: 15px;
            top: 170px;

            & .accordion-button {
                background-color: $primaryColor;
                color: #FFF;
                padding: 10px 30px;
                height: auto;
            }
        }
    }
}

@media (max-width: 767px) {
    .bg-tour-list {
        height: 220px !important;
        background-position: bottom left;
    }

    .stars-tour-list {
        margin-bottom: .375rem;
        & svg {
            font-size: 1rem !important;
        }
    }

    .text-custom {

        & .text-tour-info {
            margin-bottom: 0rem !important;
        }
    }

    .price {

        & h5 {
            font-size: 1.25rem !important;
            margin-bottom: 0rem !important;
        }
    }

    .btn-details button {
        font-size: .875rem !important;
        padding: 5px 12.5px !important;
    }

    .pr-3 {
        padding-right: .5rem;
    }
    
    .pl-3 {
        padding-left: .5rem;
    }

    .bg-sale-time {
        display: flex;
        flex-direction: column;

        & span {
            margin: 5px 0 !important;
        }
    }

    .bg-accordion-options {
        padding: 5px 0px;
    }
}
@import "../../assets/sass/_variables.scss";

.multi-locale-days .rmdp-day {
    width: 55px;
    height: 55px;
}

.rmdp-day.rmdp-today span {
    background-color: #6C6C6C80 !important;
    color: $text-color;
}

.rmdp-range {
    background-color: $primaryColor !important;
    box-shadow: 0 0 3px $primaryColor;
    color: $text-color;
}
.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: $primaryColor;
    color: $text-color;
}

.rmdp-toolbar {
    justify-content: flex-end !important;

    div{
        background-color: $primaryColor;
        margin-right: 15px;
    }
}

.rmdp-arrow {
    border: solid $primaryColor !important;
    border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow-container:hover {
    background-color: $primaryColor;
    box-shadow: 0 0 3px #8798ad;
    .rmdp-arrow {
        border: solid $text-color !important;
        border-width: 0 2px 2px 0 !important;
    }
}


// .rmdp-arrow-container:hover {
//     background-color: $primaryColor;
//     box-shadow: 0 0 3px #8798ad;
// }

.rmdp-toolbar div:first-child {
    background-color: white !important;    
    border: 1px solid $primaryColor;
    color: $primaryColor;
}

.rmdp-toolbar div:last-child {
    background-color: $primaryColor !important;    
    border: 1px solid $primaryColor;
    color: #FFF;
    margin-left: 1rem;
}

.rmdp-header-values {
    font-size: 18px;
}

.rmdp-week-day {
    font-size: 12px;
}

.rmdp-day-picker {
    padding: 5px;
    justify-content: space-between;
    gap: 35px;
}

.rmdp-week-day {
    color: #000 !important;
}

.rmdp-day span {
    border-radius: initial;
}

.rmdp-container {
    width: 100% !important;
}

.calendar-input {
    //padding: 0.375rem 0.75rem 0.375rem 1.8rem !important;
    font-size: 0.875rem;
    border-radius: 3px !important;
    border: 1px solid #d2d8dd;
    margin-bottom: 8px;
}

.legenda-container {
    border-top: 1px solid rgba($color: #707070, $alpha: .3);
    padding: 10px 0;

    .legenda {
        display: flex;
        justify-content: space-evenly;

        span {
            width: 26%;
            color: white;
            border-radius: 45%;
            padding: 5px;

            &.not-available {
                background-color: #8798ad;
            }

            &.normal-price {
                background-color: #707070;
            }

            &.on-sale {
                background-color: #163264;
            }
        }
    }
}

.prod-options {
    & #date->div>div:nth-child(3) {
        left: unset !important;
        right: 0 !important;
        transform: translateY(58px) !important;
    }

    & .multi-locale-days {
        left: 50% !important;
        top: 48px !important;
        transform: unset !important;
    }
}

.bg-price {
    form>div.rmdp-container>div:nth-child(3) {
        left: unset !important;
        right: 0 !important;
        transform: translateY(58px) !important;
    }

    .multi-locale-days {
        left: 50% !important;
        top: 48px !important;
        transform: unset !important;
    }
}

.rmdp-mobile .rmdp-day, .rmdp-mobile .rmdp-week-day {
    height: 52px !important;
    width: 52px !important;
}

.rmdp-mobile .rmdp-action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;
}

.modal-custom .modal-body button {
    font-size: 20px;
    width: auto !important;
    background-color: #FFF;
    color: #707070;
    border: 1px solid #707070 !important;
    margin: 15px 3px !important; 
}
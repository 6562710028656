.audit-table {
    & .table-container {
        & .table {

            & thead tr th:nth-child(1),
            tbody tr td:nth-child(1) {
                width: 20%;
                padding-left: 20px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            & thead tr th:nth-child(2),
            tbody tr td:nth-child(2) {
                width: 15%;
            }

            & thead tr th:nth-child(3),
            tbody tr td:nth-child(3) {
                width: 15%;
            }

            & thead tr th:nth-child(4),
            tbody tr td:nth-child(4) {
                width: 50%;
            }

            & thead tr th:nth-child(5),
            tbody tr td:nth-child(5) {
                width: 5%;
                text-align: center;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }

    & .dropdown-item {
        & svg {
            width: 20px;
        }
    }
}

.transaction-details {
    & .modal-dialog.modal-lg {
        max-width: 575px;
    }

    & .modal-content {
        max-height: 640px;
        padding: 1.5rem !important;

        & .modal-body {
            padding: 5rem 0 2rem !important;

            & h6 {
                font-weight: 400;
            }
        }
    }
}

.text-wrap-custom-audit {
    -webkit-line-clamp: 1 !important;
    word-wrap: break-word;
}
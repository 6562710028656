@import '../../../../../../assets/sass/_variables.scss';

.single-session-tranfers {
    & .suppliers {
        padding-bottom: 1.25rem;
        border-bottom: 1px solid #70707050;
        & span {
            font-weight: 500;
        }
    }
    p{
        margin: 0;
    }

    .pax{
        margin: 20px 0 50px;

        .bg-pendente{
            max-width: 150px;
            width: 100%;
            background-color: #E27376;
            color: #FFF;
            border-radius: 10px;
            padding: 2px 10px;
            text-align: center;
        }
        
        .total-pax{
            display: flex;
            align-items: center;
            padding: 0 50px;
            //font-size: .875rem;
            font-weight: 400;
        }
        
        .pax-units{
            display: flex;
            align-items: center;
            padding: 0 50px;
            border-left: 1px solid #70707050;
            //font-size: .875rem;
            font-weight: 400;
        }
    }

    .recursos{

        .circled{
            display: flex;
            justify-content: center;
            align-items: center;
            width: unset;
            border: 1px solid #B4B4B4;
            border-radius: 50%;
            padding: 10px;
            width: 4.25rem;
            height: 4.25rem;
            & svg {
                color: #B4B4B4;
            }
        }

        .forms{
            display: flex;
            max-width: calc(100% - 100px);
            justify-content: space-between;

            .tipo{
                display: block;
                width: 100%;
                padding: 0.375rem 2.25rem 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
                background-color: #fff;
                background-repeat: no-repeat;
                background-position: right 0.75rem center;
                background-size: 16px 12px;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                appearance: none;
                text-transform: uppercase;
            }

            .action-btn{

                button{
                    background-color: transparent;
                    border-color: transparent;
                    color: $primaryColor;
                    
                    &::before{
                        display: none;
                        
                    }
                }
            }
        }


        .new-resource{
            button{
                background-color: transparent;
                border: 1px solid #707070;
                border-radius: 5px;
                color: #707070;
                font-weight: 500;
                padding: 2.5px 40px;
                &:hover {
                    color: #FFF;
                    background-color: #707070;
                }
            }
        }
        
    }
}

.modal-image {
    & .modal-header {
        padding: 20px 0 50px;
        width: 100%;
        padding: 0 25px !important;
        top: 25px;
        color: #6731FF;
    }

    & hr {
        margin: 80px 0 0 !important;
    }

    & .modal-body {
        padding: 30px 15px !important;
    }
    & .modal-content {
        width: 660px !important;
        padding: 0 20px;
        & .bg-modal-image {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 560px;
        }

        & span {
            display: block;
        }

        & .col, .col-md-8, .col-md-4 {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        & h6 {
            color: $primaryColor;
        }
    }
}
@import '../../assets/sass/_variables.scss';

.container-checkout {

    .checkout-title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 26px 0 55px 0;

        .back {
            cursor: pointer;
            color: red;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 600;
        }

        h1 {
            font-size: 28px;
            color: #707070;
            font-weight: 600;
            margin: 0;
        }
    }

    .container-cards {


        .cart-title,
        .payment-title {
            font-size: 28px;
            font-weight: 400;
            color: #707070;
            margin-top: 40px;
            margin-bottom: 20px;
        }

        .card-title {
            margin-bottom: 0px;

            h5 {
                font-size: 18px;
                color: $primaryColor;
                margin-bottom: 0;
            }
        }

        .card-unit {
            margin-bottom: 20px;
            box-shadow: 0px 3px 6px #00000029;


            .card-title {
                margin-bottom: 40px;

                h4 {
                    font-size: 18px;
                    color: $primaryColor;
                }
            }

            .details-title {
                font-size: 18px;
                color: #707070;
            }

            small {
                font-weight: 400;
            }
        }

        h3 {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
            margin-top: 30px;
            margin-bottom: 20px;
        }

        .cart-card-unit {
            .card-title {
                margin-bottom: 40px;

                .logo-button {
                    display: flex;
                    justify-content: space-between;

                    button {
                        background-color: transparent;
                        border: none;
                    }
                }

                h4 {
                    font-size: 20px;
                    color: #707070;
                }

                p {
                    font-size: 12px;
                    margin-bottom: 0;

                    span {
                        color: $primaryColor;
                        font-weight: 700;
                    }
                }
            }

            .participants {

                h3 {
                    font-size: 16px;
                    font-weight: 500;
                    color: #707070;
                    margin-bottom: 30px;
                }

                .person-form {
                    margin-bottom: 40px;

                    h4 {
                        font-size: 16px;
                        font-weight: 400;
                        color: #707070;
                        margin-bottom: 20px;
                    }
                }
            }

            hr {
                margin-top: 20px;
                margin-bottom: 30px;
                color: rgba(112, 112, 112, .5);
            }

            .form-textarea {
                display: flex;
                flex-direction: column;

                textarea {
                    max-height: 120px;
                }
            }
        }

        .pix-box {
            max-width: 440px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 30px 0;
            border: 1px solid rgba(112, 112, 112, .5);
            padding: 30px;

            p {
                font-size: 12px;
            }

            hr {
                height: 1px;
                width: 100%;
            }

            .pix-total {
                width: 100%;
                display: flex;
                justify-content: space-between;

                p {
                    font-size: 18px;
                    font-weight: 700;
                }
            }
        }

        .btn {
            border: 1px solid #ffffff;
            border-radius: 0.35rem;
            color: #ffffff !important;
            font-size: 1rem;
            font-weight: 400;
            padding: 5px 60px;
            margin: 20px 0;
        }

    }

    .container-details {

        .card-title {
            margin-bottom: 0px;

            h5 {
                font-size: 18px;
                color: $primaryColor;
                margin-bottom: 0;
            }
        }

        .card-unit {

            .container-fluid {
                background-color: white;
                padding: 12px;
            }

            .details-title {
                color: #707070;
                font-size: 18px;
            }

            .details-date {
                margin-bottom: 20px;

                small {
                    font-size: 12px;
                    color: #707070;
                }
            }

            .details-boarding {
                margin-bottom: 12px;

                p {
                    font-size: 11px;
                    color: #707070;
                    margin-bottom: 0;

                    strong {
                        color: $primaryColor;
                    }
                }
            }

            .details-price {
                margin-bottom: 12px;

                .price-per {
                    display: flex;
                    justify-content: space-between;

                    p {
                        min-width: 45px !important;
                        font-size: 10px;
                        margin-bottom: 0;
                        text-align: left;

                        &:nth-child(1) {
                            min-width: 160px !important;
                        }
                    }
                }
            }

            .total-price {
                width: 100%;

                p {
                    text-align: right;
                    color: #707070;
                    font-size: 12px;

                }
            }
        }

        .cupom {
            display: flex;
            flex-direction: column;
            align-items: center;

            .accordion-header {

                .accordion-button {
                    color: #707070;
                    font-weight: 500;
                    font-size: 12px;
                    max-height: 49px;
                }
            }

            .btn {
                border: 1px solid #707070;
                border-radius: 0.35rem;
                color: #707070 !important;
                font-size: 1rem;
                font-weight: 400;
                padding: 5px 20px;
                margin: 5px 0;
            }
        }

        .policy {
            background-color: $primaryColor;

            .card-title {
                color: #FFF;
                font-weight: 500;
                font-size: 12px;
                max-height: 49px;
                margin: 0;
                margin-bottom: 17px;
            }

            .policy-btn {
                background-color: transparent;
                border: 1px solid #fff;
                border-radius: 10px;
                color: white;
                font-size: 10px;
            }

        }
    }

    .container-cards {


        .btn-primaryColor {
            border: 1px solid #707070;
            border-radius: 0.35rem;
            font-size: 1rem;
            font-weight: 400;
            padding: 5px 65px;
            margin: 20px 0;
            color: #fff;
            text-align: end;
        }
    }

    .credit-card {
        display: flex;
        justify-content: flex-start;

        .rccs {
            margin: 15px 0;
        }
    }
}

button.btn-primaryColor.btn-payment {
    padding: 15px 65px !important;
    border: none;
}
@import "../../../../assets/sass/_variables.scss";

.manifest-table {
    & .table-container {
        & .table {

            & thead tr th:nth-child(1),
            tbody tr td:nth-child(1) {
                width: 8%;
                padding-left: 20px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            & thead tr th:nth-child(2),
            tbody tr td:nth-child(2) {
                width: 8%;
            }

            & thead tr th:nth-child(3),
            tbody tr td:nth-child(3) {
                width: 8%;
                justify-content: center;
                text-align: center;
            }

            & thead tr th:nth-child(4),
            tbody tr td:nth-child(4) {
                width: 8%;
                justify-content: center;
                text-align: center;
            }

            & thead tr th:nth-child(5),
            tbody tr td:nth-child(5) {
                width: 16%;
            }

            & thead tr th:nth-child(6),
            tbody tr td:nth-child(6) {
                width: 44%;
            }

            & thead tr th:nth-child(7),
            tbody tr td:nth-child(7) {
                width: 8%;
                justify-content: center;
                text-align: center;
            }

            & thead tr th:nth-child(8),
            tbody tr td:nth-child(8) {
                width: 8%;
                justify-content: center;
                text-align: center;
            }

            & thead tr th:nth-child(9),
            tbody tr td:nth-child(9) {
                width: 4%;
                text-align: center;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
}


.resumo-container {
    display: none;
    width: 650px;
    background-color: white;
    border: 1px solid #707070;
    position: absolute;
    z-index: 10;
    padding: 10px 15px;
    flex-direction: column;
    align-items: flex-start;

    &:hover {
        display: block;
    }
}

.resumo:hover {

    .resumo-container {
        display: flex;

        & thead {
            & tr {
                border: none !important;
            }
        }

        /* ===== Scrollbar CSS ===== */

        /* Firefox */

        * {
            scrollbar-width: thin;
            scrollbar-color: #c9c9c9 #ffffff;
        }

        /* Chrome, Edge, and Safari */
        *::-webkit-scrollbar {
            width: 10px;
        }

        *::-webkit-scrollbar-track {
            background: #ffffff;
        }

        *::-webkit-scrollbar-thumb {
            background-color: #c9c9c9;
            border-radius: 20px;
            border: 2px solid #ffffff;
        }

        h6 {
            text-align: left;
            color: $primaryColor;
        }

        span {
            color: #707070;
            text-align: left;
        }

        .icon-inactive {
            aspect-ratio: 1 / 1;
            width: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #707070;
            border-radius: 50%;
            background-color: white;
            margin: 0 5px 0 0;

            * {
                color: #707070;
            }
        }

        .icon-solicitado {
            aspect-ratio: 1 / 1;
            width: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #ffffff;
            border-radius: 50%;
            background-color: #ffeeba;
            margin: 0 5px 0 0;

            * {
                color: #707070;
            }
        }

        .icon-active {
            aspect-ratio: 1 / 1;
            width: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #FFFFFF;
            border-radius: 50%;
            background-color: #7DD4AB;
            margin: 0 5px 0 0;

            * {
                color: #FFFFFF;
            }
        }

        .table {
            max-height: 300px;
            overflow-y: scroll;
        }

        tbody tr {
            margin: 10px 0;
            border: 1px solid rgb(206, 206, 206) !important;
        }

        & thead tr th:nth-child(1),
        tbody tr td:nth-child(1) {
            width: 20%;
            padding-left: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        & thead tr th:nth-child(2),
        tbody tr td:nth-child(2) {
            width: 10%;
        }

        & thead tr th:nth-child(3),
        tbody tr td:nth-child(3) {
            width: 30%;
        }

        & thead tr th:nth-child(4),
        tbody tr td:nth-child(4) {
            width: 20%;
        }

        & thead tr th:nth-child(5),
        tbody tr td:nth-child(5) {
            width: 20%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

    }
}
// .photo {
//     .bg-images {
//         cursor: grab;
        
//         .btn.trash {
//             height: "40px";
//             position: absolute;
//             cursor: pointer;
//             right: 0;
//             background-color: white;
//             border-radius: 50%;
//             color: black;
//         }
//     }
// }

.gap-4{
    display: flex;
    flex-wrap: wrap;
    user-select: none !important;

    span.btn.trash {
        position: absolute;
        right: 5px;
        top: 5px;
        background: #fff;
        border-radius: 50%;
        width: 34px;
        height: 33px;
        font-size: 12px;
        padding: 5px;
    }
}

.gap-4 .item-drag:first-child {
    //width: 100%;
    height: 400px;
    max-width: 100%;

    &::before{        
    content: 'Imagem principal';
    font-weight: 600;
    // z-index: 100;
    position: absolute;
    font-family: "Font Awesome 5 Free", "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    top: 5px;
    font-size: 14px;
    left: 5px;
    background-color: #135a87;
    padding: 5px;
    color: #fff;
    }

    span.btn.trash {
        //left: 480px;
        top: 5px;
    }

}

.item-drag {
    cursor: grab;
    margin: 8px;
    display: flex;
    position: relative;
    flex-shrink: 0;
    user-select: none;
    height: 250px;
    max-width: 310px;
    overflow: hidden;
}

.imageGrap{
    pointer-events: none;
}
.vertical-timeline-element-content {
    box-shadow: none;
    padding-top: 0;
    padding-bottom: 0;

    & span {
        font-size: 1rem;
        font-weight: 600;
    }
}

.reservation-logs {

    .mb-10px {
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .mb-20px {
        margin-top: 0px;
        margin-bottom: 20px;

    }

    .mb-40px {
        margin-top: 0px;
        margin-bottom: 40px;

    }

    hr {
        opacity: 0.25;
    }
}